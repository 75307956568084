.theme-toggle {
  /* border: 1px solid var(--clr--accent); */
  border-radius: 4px;
  font-size: 1.2rem;
  padding: 0.5em 0.75em;
  margin-right: 1em;
  display: flex;
  align-items: center;
  gap: 0.25em;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.theme-toggle:hover {
  scale: 1.1;
}
