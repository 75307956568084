.sidebar {
  /* background-color: #fff; */
  width: 180px;
  position: fixed;
  top: 64px;
  bottom: 0px;
  left: -180px;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.4s;
}

.sidebar.open {
  left: 0;
}

.tab-content {
  height: 100%;
  /* overflow-x: auto; */
  /* background-color: #fff; */
}

.tab-content > .tab-pane {
  background-color: transparent;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  display: block;
}
.menu-nav > li > a > i.ssicon {
  color: #727376;
  font-size: 2rem !important;
  margin: 0 10px !important;
}

.menu-nav {
  margin: 0 0 10px;
  /* background-color: #fff; */
  list-style: none;
  padding: 0;
  position: relative;
}

.menu-nav > li {
  /* border-top: 1px solid #fff; */
  border-bottom: 1px solid var(--clr--primary);
  text-transform: uppercase;
  font-family: GOTHIC;
  border-top-color: transparent;
  position: relative;
}

.menu-nav > li.active {
  border-top-color: transparent;
  background-color: var(--clr--accent);
}

.menu-nav > li + li {
  /* margin-top: 2px; */
}

.menu-nav > li > a {
  /* color: #505b72; */
  padding: 8px 5px;
  border-radius: 0;
  font-weight: 700;
  transition: all 0.2s ease-out 0s;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
}

.menu-nav > li.active > a > i {
  /* color: #fff; */
}

.menu-nav > li > a > i {
  width: 16px;
  font-size: 14px;
  text-align: center;
  margin-right: 7px;
  color: #259dab;
  vertical-align: top;
  margin-top: 1.5555px;
  transition: all 0.2s ease-out 0s;
}

.menu-nav > li > a .badge {
  background-color: transparent;
  color: #9fa8bc;
  font-size: 12px;
  text-transform: none;
  padding: 0;
  font-weight: normal;
  margin-top: 4px;
  margin-right: 2px;
}

.menu-nav > li > a > img.sicon {
  height: 25px;
  width: 35%;
  padding: 0 5px;
}

.menu-nav > li > a > span.txt {
  font-weight: 600;
  font-size: 10px;
  display: block;
  width: 75%;
}

.menu-nav > li.active > a > span.txt {
  /* color: #fff; */
}

/* .menu-nav > li.nav-parent > a:after {
  content: "\f107";
  font-family: "FontAwesome";
  font-size: 16px;
  font-weight: 400;
  color: #bdc3d1;
  position: absolute;
  top: 10px;
  right: 15px;
} */

.menu-nav > li.active > a:after {
  color: #fff;
}

.menu-nav .children {
  list-style: none;
  display: none;
  margin: 0;
  padding: 0;
  position: relative;
  border-top: 1px solid var(--clr--primary);
  background-color: var(--bg--body);
  transition: all 0.2s ease-out 0s;
}

.menu-nav > li.active .children {
  display: block;
  border-top: 0;
  border-bottom: 1px solid var(--clr--primary);
}

.menu-nav > li.active .children:before {
  border-top: 7px solid #ff7200;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 0;
}

.menu-nav .children > li {
  margin-left: 21px;
  border-left: 1px solid var(--clr--primary);
  font-family: GOTHIC;
  text-transform: none;
  letter-spacing: normal;
  display: block;
  font-size: 12px;
}

.menu-nav .children > li:first-child {
  padding-top: 7px;
}

.menu-nav .children > li > a {
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 5px 0 5px 18px;
  color: var(--clr--primary);
  position: relative;
  height: 28px;
  transition: all 0.2s ease-out 0s;
  font-size: 10px;
}

.menu-nav .children > li.active > a {
  color: var(--clr--accent);
}

.menu-nav .children > li > a:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  left: -3px;
  top: 50%;
  background-color: #9fa8bc;
  margin-top: -3px;
  border-radius: 50px;
  transition: all 0.2s ease-out 0s;
}

.menu-nav .children > li.active > a:before {
  background-color: var(--clr--accent);
}

.menu-nav .children > li > a:after {
  content: "";
  width: 30px;
  height: 30px;
  border: 1px solid var(--clr--primary);
  position: absolute;
  left: -15px;
  top: 50%;
  margin-top: -15px;
  border-radius: 50px;
  opacity: 0;
  transition: all 0.2s ease-out 0s;
}

.menu-nav .children > li.active > a:after {
  opacity: 1;
  border-color: var(--clr--accent);
  width: 11px;
  height: 11px;
  left: -6px;
  margin-top: -6px;
}

.menu-nav .children > li > a:hover:before {
  background-color: var(--clr--primary-hover);
}

.menu-nav .children > li > a:hover:after {
  opacity: 1;
  border-color: var(--clr--primary-hover);
  width: 11px;
  height: 11px;
  left: -6px;
  margin-top: -6px;
}

.menu-nav .children > li > a:hover {
  text-decoration: none;
  color: var(--clr--primary-hover);
}

.sidebar__icons {
  color: var(---clr--primary);
  font-size: 2rem !important;
  margin: 0 10px !important;
}
.icon__active {
  color: var(--clr--primary-active);
}
