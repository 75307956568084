@font-face {
  font-family: GOTHIC;
  src: url("./../fonts/GOTHIC.TTF");
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --gray-100: #e4e6eb;
  --gray-200: #d0d2d7;
  --gray-300: #b0b3b8;
  --gray-500: #727376;
  --gray-600: #3a3b3c;
  --gray-700: #555557;
  --gray-800: #242526;
  --gray-900: #18191a;

  --dark: #222;
  --green-500: #25a244;
  --green-600: #208b3a;
  --red--500: rgb(238, 50, 50);
  --red--600: rgb(217, 47, 47);
  --clr--accent: #ff7200;
  --clr--accent-alt: #f57c00;
  --modalInputFlexBasis: 30%;

  --clr--pdf--500: #e65149;
  --clr--pdf--600: #f40f02;
  --clr--xls--500: #2da362;
  --clr--xls--600: #1d6f42;
}
/* default theme */
html {
  --bg--body: #fff;
  --bg--main: var(--gray-100);
  --clr-neutral: var(--gray-100);
  --clr-neutral-hover: var(--gray-300);
  --clr--primary: var(--gray-500);
  --clr--primary-hover: var(--gray-700);
  --clr--primary-active: #fff;

  --btn--clr-hover: var(--gray-100);
  --scrollbar-bg: var(--gray-100);
  --scrollbar-clr: var(--gray-500);
}
html[data-theme="dark"] {
  --bg--body: #131418;
  --bg--main: #0c0d11;
  /* --clr-neutral: var(--gray-100);
  --clr-neutral-hover: var(--gray-300) */
  --clr--primary: var(--gray-500);
  --clr--primary-hover: var(--gray-300);
  --clr--primary-active: var(--gray-100);

  --btn--clr-hover: var(--gray-800);
  --scrollbar-bg: var(--gray-800);
  --scrollbar-clr: var(--gray-500);
}
body,
.sidebar,
.tab-content,
.tab-pane,
.headerpanel,
.headerpanel .logopanel,
.menu-nav,
.cust-breadcrumb {
  transition: all 0.2s ease-in-out;
  font-family: GOTHIC;
  background-color: var(--bg--body) !important;
  color: var(--clr--primary) !important;
}

.main-content {
  background-color: var(--bg--main);
}
.menu-nav > li > a {
  color: var(--clr--primary) !important;
}
.menu-nav > li.active > a > span.txt {
  color: var(--clr--primary-active);
}

.a-self-center {
  align-self: center;
}

.w-5 {
  width: 5em;
}
.w-10 {
  width: 10em;
}
.gap-zero {
  gap: 0;
}
.gap-one {
  gap: 1em;
}

.clr-accent {
  color: var(--clr--accent);
}
button:focus,
button:focus-visible {
  border: none;
  outline: none;
}
button {
  /* transition: all 0.1s ease-in-out; */
}
table {
  border-collapse: collapse;
}
/* .custom-scrollbar */

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.scroll.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
}

/* Track */

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* NEW STYLES - Nashit */
.outer-wrapper {
  background-color: var(--bg--body);
  /* border-radius: 4px; */
  margin: 1em;
  padding: 1em;
  min-height: 80vh;
  position: relative;
}

/* Panel */
.panel {
  display: flex;
  justify-content: space-between;
  /* padding: 1em 0; */
}
.panel {
  align-items: center;
}
.panel_secondary {
  display: flex;
}

.panel__heading {
  font-size: 1.5rem;
  color: var(--clr--primary);
}
.panel__items {
  display: flex;
  align-items: center;
  gap: 1.5em;
}

.panel-btn,
.panel-btn-secondary {
  background-color: var(--clr--primary);
  border: none;
  border-radius: 4px;
  color: var(--bg--body);
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.25em;
  /* margin-right: 1.5em; */
  padding: 0.5em 1em;
}
.panel-btn-secondary {
  background-color: var(--bg--main);
  display: block;
  font-size: 1.2rem;
  margin: 0;
  text-align: center;
  width: 9em;
}
.panel-btn:hover {
  background-color: var(--clr--primary-hover);
  color: var(--btn--clr-hover);
}
.panel_secondary > .panel__items > div {
  display: flex;
  background-color: var(--clr--accent);
  border-radius: 4px;
}

.panel-btn-secondary {
}

.reports .panel__items {
  /* border: 1px solid red; */
}
.reports .panel__items .filterForm {
  display: block;
}
.reports .panel__items .filterForm > div {
  /* align-items: center; */
  display: flex;
}

.reports__search {
  width: min(60%, 35em);
  gap: 0.5em;
}
.reports__search > input:focus {
  border: 1px solid var(--clr--primary);
  box-shadow: none;
}
.reports__search-icon {
  display: flex;
  align-items: center;
  gap: 0.25em;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.reports__search-icon:hover {
  border: 1px solid var(--clr--primary);
}
.bg-accent {
  /* border: 1px solid var(--clr--accent); */
  background-color: var(--clr--accent);
  color: var(--gray-200);
}
.bg-accent-outline {
  /* border: 1px solid var(--clr--accent); */
  color: var(--clr--primary-hover);
}

.bg-gray-100 {
  background-color: var(--bg--main);
  color: var(--clr--primary-hover);
}

.bg-gray-400 {
  background-color: var(--gray-500);
  color: var(--gray-100);
  border: 1px solid var(--gray-500);
}
.bg-gray-400:hover {
  background-color: var(--gray-500);
  color: var(--gray-100);
}

.bg-green-600 {
  background-color: rgb(129, 181, 129);
  color: var(--clr--primary-hover);
}

.view-icon {
  color: var(--clr--accent);
}
.view-icon:hover {
  color: var(--clr--accent-alt);
}
.edit-icon {
  color: var(--clr--primary);
  fill: var(--clr--primary);
}
.edit-icon:hover {
  color: var(--clr--primary-hover);
  fill: var(--clr--primary-hover);
}
.delete-icon {
  color: var(--red--600);
  fill: var(--red--600);
}
.delete-icon:hover {
  color: var(--red--500);
  fill: var(--red--500);
}
.accent-icon {
  color: var(--clr--accent);
  fill: var(--clr--accent);
}
.accent-icon:hover {
  color: var(--clr--accent-alt);
  fill: var(--clr--accent-alt);
}

td.action {
  /* background-color: red; */
  width: 8% !important;
  white-space: nowrap;
}
.action-icons {
  display: flex;
  justify-content: space-around;
  gap: 1em;
  /* text-align: left; */
  /* width: 60%; */
}
.action-icons > svg {
  /* color: green; */
  /* margin-right: 1em; */
}

.allocate-icon {
  color: var(--red--600);
}
.deallocate-icon,
.add-icon {
  color: var(--green-600);
  fill: var(--green-600);
}
.add-icon:hover {
  color: var(--green-500);
  fill: var(--green-500);
}
.icons {
  width: 1.75em;
  height: 1.75em;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.generic-table-wrapper {
  overflow-x: scroll;
}
.generic-table thead th,
.stageDashboard-table thead th {
  color: var(--bg--body);
}
.generic-table thead th,
.generic-table tbody td,
.stageDashboard-table thead th,
.stageDashboard-table tbody td {
  border: none;
  max-width: 8rem;
  word-wrap: break-word;
}
.generic-table thead th:last-child,
.generic-table tbody td:last-child,
.stageDashboard-table thead th:last-child,
.stageDashboard-table tbody td:last-child {
  max-width: max-content;
}
.generic-table tbody,
.stageDashboard-table tbody {
  background-color: var(--bg--body);
}
.generic-table,
.stageDashboard-table {
  margin: 1em auto;
  text-align: center;
}
.generic-table,
.stageDashboard-table {
  border: 1px solid var(--clr--primary);
}

.generic-table thead th,
.generic-table tbody td,
.stageDashboard-table thead th,
.stageDashboard-table tbody td {
  border-right: 1px solid var(--clr--primary);
  border-bottom: 1px solid var(--clr--primary);
}

/* dashboard */

.adminDashboard__line-container {
  border: 1px solid var(--clr--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hide {
  display: none;
}

.adminDashboard__dropdown-icon-wrapper,
.sublineContainer-icon-wrapper,
.stagesContainer-icon-wrapper {
  position: absolute;
  right: 2em;
  top: 1em;
  scale: 2;
  color: var(--bg--body);
}

.sublineContainer-icon-wrapper,
.stagesContainer-icon-wrapper {
  scale: 1.2;
  top: 0.5em;
}

.adminDashboard__line-container {
  margin: 1em;
  border-radius: 4px;
  position: relative;
  background-color: var(--clr--accent);
  flex: 1;
}

.adminDashboard__subline-wrapper {
  width: 100%;
  background-color: var(--bg--main);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.collapsed {
  flex: 0;
}
.adminDashboard__heading {
  color: var(--bg--body);
  font-size: 1.75rem;
  font-weight: bold;
  border-radius: 4px;
}

.adminDashboard__subline-container,
.adminDashboard__stages-container {
  margin: 1.5rem 1em;
  margin-bottom: 3em;
  border: 1px solid var(--clr--primary);
  border-radius: 4px;
}
.subline__stageWrapper {
  padding: 1em;
}
.stages_wrapper {
  padding: 1em;
}
.stage__container {
  display: flex;
  overflow-x: scroll;
  padding: 1em;
  /* margin-top: 1.5em; */
}

.adminDashboard__subline-heading {
  border: 1px solid var(--clr--primary-hover);
  background-color: var(--clr--primary-hover);
  color: var(--bg--main);
  font-weight: bold;
  font-size: 1rem;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
}

.adminDashboard__subline-card {
  background-color: var(--bg--body);
  border: 1px solid var(--clr--primary-hover);
  border-radius: 4px;
  color: var(--clr--primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-basis: 200px;
  flex-direction: column;
  flex-shrink: 0;
  gap: 1em;
  min-height: 150px;
  transition: all 0.2s ease-out;
  /* border: 1px solid red;  */
}
.stageCard__assetInfo {
  background-color: var(--clr--primary-active);
  border-radius: 4px;
  color: var(--dark);
  padding: 0 0.5em;
  width: 95%;
  height: auto;
  position: relative;
  text-align: center;
}
.stageHighlight {
  box-shadow: 0 0 6px 2px var(--clr--accent);
}
.adminDashboard__subline-card:hover {
  scale: 1.03;
}
.subline-card__assetName {
  display: inline-block;
  width: 100px;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
}
/* .assetName-tooltip {
  background-color: var(--clr--primary);
  border-radius: 4px;
  color: var(--bg--body);
  padding: 0 0.25em;
  position: absolute;
  top: -15px;
  left: 45px;
  display: none;
  transition: all 0.1s ease-out;
  white-space: nowrap;
}
.subline-card__assetName:hover + .assetName-tooltip {
  display: block;
} */

.subline-card__heading {
  background-color: var(--clr--accent);
  color: var(--clr-neutral);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
  width: 100%;
}
.subline-card__heading > div {
  display: flex;
  justify-content: space-around;
  gap: 0.5em;
}
.subline-card__heading > div > span {
  /* border: 1px solid red; */
}
.subline-card__stats {
  width: 65%;
  /* margin-top: auto; */
  /* margin-bottom: 2.5em; */

  font-size: 0.8rem;
  font-weight: bold;
  /* border: 1px solid red; */
}
.subline-card__stats > div {
  display: flex;
  justify-content: space-between;
}

.subline-card__completed,
.updatedQty {
  color: var(--green-500);
}
.subline-card__pending,
.userName {
  color: var(--clr--accent-alt);
}
.subline-card__missed,
.prevQty {
  color: var(--red--500);
}

.right_arrow_wrapper {
  /* border: 3px solid var(--clr--primary-hover); */
  border-radius: 50%;
  /* height: 30px; */
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2em;
}
.right_arrow-icon {
  height: 1.5em;
  width: 1.5em;
  color: var(--clr--primary-hover);
  scale: 1.5;
}

.stage__form__sequence {
  display: flex;
  justify-content: space-around;
}
.stage__form__icons {
  display: flex;
  justify-content: space-around;
}
.stageForm-row {
  position: relative;
}
.addStage-icon {
  position: absolute;
  right: -15px;
  top: 13px;
  scale: 1.1;
}

.btn-disable {
  cursor: not-allowed;
}

.stage_count,
.asset_count {
  position: absolute;
  color: var(--clr--accent);
  font-weight: bold;
  font-size: 0.75rem;
  top: -2em;
  right: 1em;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.add_stage-btn {
  border: none;
  background-color: var(--clr--accent);
  cursor: pointer;
  color: var(--bg--body);
  display: flex;
  align-items: center;
  gap: 0.1em;
  border-radius: 4px;
  padding: 0.25em;
  font-size: 0.75rem;
  transition: all 0.2s ease-out;
}
.add_stage-btn:hover {
  background-color: var(--clr--accent-alt);
}

.flow-settings__breadcrumb {
  /* border: 1px solid red; */
}
.flow-settings__breadcrumb ul > li:first-child {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
}
.flow-settings__breadcrumb ul {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 0.75rem;
}
.flow-settings__breadcrumb li {
  list-style: none;
  font-size: 1rem;
}

.flow-settings__breadcrumb-arrow {
  font-weight: bold;
  margin-left: 0.5em;
}
.flow-settings__breadcrumb-home-icon {
  scale: 1.2;
}

.stageAssets__status-completed,
.stageAssets__prevStage-passed {
  color: var(--green-600);
  font-weight: bold;
}
.stageAssets__status-pending,
.stageAssets__prevStage-missed {
  color: var(--red--600);
  font-weight: bold;
}

.addForm-icon {
  position: absolute;
  right: 2.5em;
  top: 0.8em;
  scale: 1.1;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.addForm-icon:hover {
  color: var(--red--500);
}

/* stageManager dashboard */

.stageDashboard-info {
  background-color: var(--clr--primary);
  color: var(--bg--body);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  font-size: 1rem;
  /* padding: 0.25em 0.25em; */
  padding-top: 0.5em;
}
.stageDashboard-stats > div {
  flex-basis: 100%;
  background-color: var(--bg--main);
  padding: 0.70em;
  text-align: center;
  /* border: 1px saddlebrown solid; */
}
.stageDashboard-heading {
  font-size: 1.25rem;
  /* border: 1px saddlebrown solid; */
}
.stageDashboard-heading > span {
  margin-left: 1em;
}
.stageDashboard-stats {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0.25em 0.25em 0.25em 0.25em;
  gap: 0.25em;
}
.stageDashboard-stats__completed {
  color: var(--green-500);
  font-weight: bold;
}
.stageDashboard-stats__pending {
  color: var(--clr--accent);
  font-weight: bold;
}
.stageDashboard-stats__missed {
  color: var(--red--500);
  font-weight: bold;
}
.stageDashboard-assetList {
  margin-top: 1em;
}
.stageDashboard-assetList > h3 {
  background-color: var(--clr--primary);
  color: var(--bg--body);
  padding: 0.25em;
  font-size: 1.25rem;
}
.stageDashboard-table {
  margin-top: 0em;
}

/* asset allocation */

.assignAccessory-wrapper {
  flex-basis: 100%;
  gap: 1em;
  margin-bottom: 1.25em !important;
}
.assignAccessory-wrapper > div {
  flex-basis: 50%;
}

.scanAccessory-btn,
.addAccessory-btn {
  background-color: var(--clr--accent);
  border: 1px solid var(--clr--accent);
  border-radius: 4px;
  color: var(--clr-neutral);
  cursor: pointer;
  padding: 0.25em 0.5em;
  transition: all 0.1s ease-out;
}
.scanAccessory-btn:hover,
.addAccessory-btn:hover {
  background-color: var(--clr--accent-alt);
}
.addAccessory-btn {
  bottom: -3em;
  right: 0;
}
.scanAccessory-btn {
  height: 38px;
  width: 100%;
}

.scanAccessory-btn2 {
  cursor: pointer;
  background-color: var(--clr--primary);
  border: 1px solid var(--clr--primary);
  color: var(--bg--body);
  padding: 0.25em 0.5em;
  border-radius: 4px;
  position: absolute;
  top: 3px;
  right: 20px;
  /* z-index: 999999 ; */
}

.view_components_btn {
  cursor: pointer;
  background-color: var(--clr--primary);
  border: 1px solid var(--clr--primary);
  color: var(--bg--body);
  padding: 0.25em 0.5em;
  border-radius: 4px;
  width: 100%;
}

.assetAccessories-backBtn,
.accessoryLogs-backBtn {
  padding: 0.25em 1em;
  border: 1px solid var(--clr--primary);
  background-color: var(--clr--primary);
  color: var(--bg--body);
  cursor: pointer;
  transition: all 0.1s ease-out;
}
.assetAccessories-backBtn:hover,
.accessoryLogs-backBtn:hover,
.scanAccessory-btn2:hover {
  background-color: var(--clr--primary-hover);
  color: var(--btn--clr-hover);
}

.accessoryTransfer-btn {
  padding: 0.25em 1em;
  border: 1px solid var(--clr--accent);
  background-color: var(--clr--accent);
  color: var(--bg--body);
  cursor: pointer;
  transition: all 0.1s ease-out;
}
.accessoryTransfer-btn:hover {
  background-color: var(--clr--accent-alt);
}

/* stage manager dashboard things */

.accessory_status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.accessory_status-icon {
  width: 24px;
  height: 24px;
}
.scan_red {
  color: var(--clr--pdf--500);
}
.scan_green {
  color: var(--clr--xls--500);
}
.accessory_scan_submit_btn {
  background-color: var(--clr--accent);
  border: 1px solid var(--clr--accent);
  transition: all 0.2s ease;
}
.accessory_scan_submit_btn:hover {
  background-color: var(--clr--accent-alt);
  border: 1px solid var(--clr--accent-alt);
}

.scan_accessories_table {
  /* border: 1px solid red; */
  overflow-y: auto;
  max-height: 250px;
  /* padding-top: ; */
}
.scan_accessories_table thead th {
  position: sticky;
  top: -1px;
  border-top: 1px solid var(--clr--primary);
  background-color: var(--clr--accent);
}

/* accessory logs */
.previousAssetDetails,
.nextAssetDetails {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.serialNo_eye {
  /* border: 1px solid red; */
  position: absolute;
  right: 0;
  top: -3px;
}
.previousAssetName,
.nextAssetName {
  /* border: 1px solid red; */
  padding: 0 2em;
}
.muted {
  pointer-events: none;
}

.pagination-wrapper {
  margin-top: 2em;
}
.pagination {
  justify-content: center;
  margin-bottom: 2rem;
}

.page-item a,
.page-link:hover {
  color: var(--clr--primary);
}

.page-item.active .page-link {
  border-color: var(--clr--accent);
  background-color: var(--clr--accent);
}

.pagination-wrapper .visually-hidden {
  display: none;
}
/* .page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.25);
} */

.confirmation-toast {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  /* width: 20em; */
}
.confirmation-toast-btn {
  border: none;
  color: var(--bg--body);
  padding: 0.25em;
  cursor: pointer;
  transition: all 0.1s ease-out;
}

.toast-confirm {
  background-color: var(--green-500);
}
.toast-confirm:hover {
  background-color: var(--green-600);
}
.toast-cancel {
  background-color: var(--red--500);
}
.toast-cancel:hover {
  background-color: var(--red--600);
}

.adminDashboard .filterForm {
  margin: 5em 1em 1em 1em;
  padding: 1em 1em 2em 1em;
  border: 1px solid var(--clr--primary);
  border-radius: 4px;
}

.filterForm {
  display: flex;
  gap: 2em;
  justify-content: center;
  margin-right: 2em;
}
.filterForm > div {
  flex-basis: 20%;
}
.filterForm > div > label,
.reportFilterInputWrapper > div > label {
  font-weight: bold;
  /* font-size: 1rem; */
}

.filterForm > div > label.required::after,
.required::after {
  content: " *";
  color: red;
}

.filterForm input,
.filterForm select,
.filterForm textarea,
.reportFilterInputWrapper input,
.reportFilterInputWrapper select,
.reportFilterInputWrapper textarea {
  border: 1px solid var(--clr--accent);
  font-size: 1rem !important;
  background-color: transparent;
}

.filterForm__input-wrapper > input {
  height: 38px;
}
.filterForm__input-wrapper input::placeholder {
  font-size: 0.8rem;
}
.filterForm input:focus,
.filterForm select:focus,
.filterForm textarea:focus,
.reportFilterInputWrapper input:focus,
.reportFilterInputWrapper select:focus,
.reportFilterInputWrapper textarea:focus {
  border-color: var(--clr--accent);
  box-shadow: 0 0 3px var(--clr--accent);
  background-color: transparent;
}

.filterForm > div > select {
  display: block;
  height: 32px !important;
  font-size: 1rem !important;
}
.filterForm > div > select > option:checked,
.filterForm > div > select > option:hover {
  background-color: var(--clr--accent) !important;
}

.adminDashboard__refreshBtn {
  /* background-color: transparent; */
  top: -43px;
}
.adminDashboard__refreshBtn-icon {
  width: 28px;
  height: 28px;
  color: var(--clr--accent);
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.adminDashboard__refreshBtn:hover .adminDashboard__refreshBtn-icon {
  color: var(--clr--accent-alt);
  scale: 1.1;
}

.serialNo_search_input {
  padding-right: 30px !important;
}
.filterForm__submit-btn,
.downloadBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25em;
  align-self: flex-end;

  padding: 0.4em 0.75em;

  background-color: var(--clr--accent);
  color: var(--bg--body);

  font-size: 1.1rem;

  border: none;
  border-radius: 4px;

  cursor: pointer;
  transition: all 0.1s ease-out;
}
.downloadBtn {
  background-color: var(--clr--primary);
  color: var(--bg--body);
}
.downloadBtn:hover {
  background-color: var(--clr--primary-hover);
  color: var(--btn--clr-hover);
}

.adminDashboard__refreshBtn {
  position: absolute;
  right: 2em;
}

.filterForm__submit-btn:hover {
  background-color: var(--clr--accent-alt);
}

/* filter form updated styles */
.asset-management .filterForm__input-wrapper {
  display: flex;
}
.asset-management .filterForm .filterForm__input-wrapper .form-control {
  border-right: none;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.asset-management .filterForm {
  justify-content: flex-start;
  flex-basis: 50%;
}
.asset-management .filterForm .form-group {
  flex-basis: 100%;
}
.asset-management .filterForm .filterSelect {
  flex-basis: 70%;
}
.asset-management .form-group {
  margin: 0;
}

.asset-management.action-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1em;
}
.asset-management.action-wrapper .master-search {
  flex-basis: 50%;
}
.asset-management.action-wrapper .master-input {
  flex-basis: 30%;
}

/* report */
.reportPanel {
  display: flex;
  justify-content: space-between;
}
.reportPanel__heading {
  flex-basis: 10%;
  display: flex;
  justify-content: center;
}

.reportFilterForm {
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
  gap: 1em;
}
.reportFilterInputWrapper {
  display: flex;
  flex-basis: 90%;
}
.reportFilterForm-top {
  display: flex;
  align-items: center;
  /* flex-basis: 60%; */
  gap: 1em;
  padding: 0 1em;
}
.reportFilterForm-top > div {
  flex-basis: 100%;
}

.reportFilterForm-bottom > div {
  flex-basis: 24.5%;
}
.reportFilterForm-bottom {
  display: flex;
  align-items: flex-end;
  gap: 1em;
  padding: 0 1em;
}

/* asset management */
.assetManagement-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification {
  align-self: flex-start;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  transition: all 0.2s ease-out;
}
.notification:hover {
  scale: 1.05;
}

.asset-dashboard-wrapper {
  background-color: var(--bg--main);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  gap: 2em;
  margin: 1.5em 0;
  padding: 1.5em;
}

.tile {
  /* height: 100px;
  width: 150px; */
  flex-basis: 23%;
  border: 1px solid var(--clr--primary);
  background-color: var(--bg--body);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2em 1em;
  transition: all 0.2s ease-out;
}
.tile:hover {
  scale: 1.05;
}
.tile-name {
  font-size: 0.8rem;
  text-align: center;
}
.tile-value {
  font-size: 2rem;
  color: var(--clr--accent);
}

/* user management */

.userManagement-header,
.assetManagement__header,
.assetForm-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2em;
}
.assetForm-header {
  margin: 0;
  height: 7.5em;
}
.userManagement-header h2,
.assetManagement__header h2 {
  margin: 0;
}

.user-type {
  width: 20%;
}
.user-type .input-group {
  justify-content: flex-start;
  gap: 0.25em;
}
.user-type .input-group > * {
  flex-basis: 100%;
}

.userManagement__form,
.assetManagement__form {
  border: 1px solid var(--clr--primary);
  border-radius: 4px;
  padding: 2em 0;
}
.user-form-inputs,
.asset-form-inputs {
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
  padding: 0 1em;
}

.userManagement .input-group,
.assetForm .input-group {
  flex-basis: 49%;
  align-items: center;
  justify-content: space-between;
}
.assetForm .input-group {
  flex-basis: 29%;
}

.userManagement .input-group label,
.assetForm .input-group label {
  font-weight: bold;
  margin: 0;
}

.userManagement .input-group input,
.assetForm .input-group input {
  max-width: 70%;
}
.userManagement__form .input-group > div,
.assetManagement__form .input-group > div {
  width: 70%;
}

.userManagement .input-group input,
.userManagement .input-group select,
.userManagement .input-group textarea,
.assetForm .input-group input,
.assetForm .input-group select,
.assetForm .input-group textarea {
  border: 1px solid var(--clr--accent);
  font-size: 1rem !important;
  background-color: transparent;
}

.userManagement .input-group input:focus,
.userManagement .input-group select:focus,
.userManagement .input-group textarea:focus,
.assetForm .input-group input:focus,
.assetForm .input-group select:focus,
.assetForm .input-group textarea:focus {
  border-color: var(--clr--accent);
  box-shadow: 0 0 3px var(--clr--accent);
  background-color: transparent;
}

.line-break {
  border-bottom: 1px solid var(--clr--primary);
  margin: 2em 0;
}

.user-permissions {
  padding: 0 1em;
}

.user-permissions__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1em;
}
.user-permissions__header h6 {
  margin: 0;
  font-weight: bold;
}
.user-permissions .form-check {
  font-weight: bold;
}

.user-permissions__body {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  gap: 1em;
}
.user-permissions__body .form-check {
  flex-basis: 19%;
}

.userManagement__form .btn-wrapper,
.assetManagement__form .btn-wrapper {
  display: flex;
  justify-content: center;
  gap: 2em;
}

.userManagement__form .btn-wrapper button,
.assetManagement__form .btn-wrapper button {
  border: none;
  border-radius: 4px;
  color: var(--bg--body);
  cursor: pointer;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;

  transition: all 0.2s ease-out;
  width: 10em;
}

.userManagement__form .form-submit-btn,
.assetManagement__form .form-submit-btn {
  background-color: var(--clr--accent);
  color: #fff !important;
}

.userManagement__form .form-submit-btn:hover,
.userManagement__form .form-submit-btn:active,
.assetManagement__form .form-submit-btn:hover,
.assetManagement__form .form-submit-btn:active {
  background-color: var(--clr--accent-alt);
  border: none;
  box-shadow: none;
}
.userManagement__form .form-cancel-btn,
.assetManagement__form .form-cancel-btn {
  background-color: var(--clr--primary);
}

.userManagement__form .form-cancel-btn:hover,
.assetManagement__form .form-cancel-btn:hover {
  background-color: var(--clr--primary-hover);
}

/* asset management */

.asset-register-btn {
  background-color: var(--clr--accent);
  border: none;
  border-radius: 4px;
  color: var(--bg--body);
  cursor: pointer;
  font-size: 1rem;
  padding: 0.375em 0.75em;
  transition: all 0.2s ease-out;
}
.asset-register-btn:hover {
  background-color: var(--clr--accent-alt);
}

.asset-type {
  flex-basis: 50%;
  display: flex;
  gap: 2em;
  align-items: center;
}

.asset-type .input-group {
  align-self: flex-start;
  flex-basis: 100%;
  gap: 0.25em;
}
.asset-type .input-group input,
.asset-type .input-group > div {
  max-width: 100%;
  flex-basis: 100%;
}

.asset-form-inputs-snd {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 1em;
}
.asset-form-inputs-snd .input-group {
  flex-basis: 40%;
  gap: 1em;
}

/* all master */

.all-master-header {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  margin-bottom: 1em;
}
.master-tabs-wrapper {
  /* border: 1px solid red; */
  flex-basis: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 2em;
}
.master-tab {
  border: 1px solid var(--clr--primary);
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.2s ease-out;
}
.master-tab:hover,
.master-tab.active {
  background-color: var(--clr--accent);
  border-color: var(--clr--accent);
  color: var(--bg--body);
}

.master-table-input,
.master-table-input:focus {
  border: 1px solid var(--clr--accent);
  font-size: 1rem !important;
}
.master-table-input:focus {
  box-shadow: 0 0 4px var(--clr--accent);
}

.master-table-wrapper td.action {
  width: 20rem !important;
}
.master-table-wrapper .action-icons {
  justify-content: center;
  gap: 1.5em;
}

/* asset management report filter */
.assetManagementReportFilterForm {
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2em;
}
.assetManagementReportFilterForm .form-group {
  margin: 0;
}
.assetManagementReportFilterForm input[type="datetime-local"],
.assetManagementReportFilterForm input[type="datetime-local"]:focus {
  border: 1px solid var(--clr--accent);
  height: 38px;
}

.assetManagementReportFilterForm input[type="datetime-local"]:focus {
  box-shadow: none;
}

.export-btn-wrap {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.report-export-txt {
  color: var(--clr--primary);
  font-size: 1.2rem;
}

.report-export-btn {
  border: 1px solid var(--clr--primary);
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25em;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.25em 0.75em;
}
.pdf-btn,
.pdf-icon {
  border-color: var(--clr--pdf--600);
  color: var(--clr--pdf--600);
  fill: var(--clr--pdf--600);
}
.pdf-btn:hover,
.pdf-btn:hover .pdf-icon {
  border-color: var(--clr--pdf--500);
  color: var(--clr--pdf--500);
  fill: var(--clr--pdf--500);
}
.xls-btn,
.xls-icon {
  border-color: var(--clr--xls--600);
  color: var(--clr--xls--600);
  fill: var(--clr--xls--600);
}
.xls-btn:hover,
.xls-btn:hover .xls-icon {
  border-color: var(--clr--xls--500);
  color: var(--clr--xls--500);
  fill: var(--clr--xls--500);
}

.physical-report-stats-wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}
.physicalReport__stat {
  flex-basis: 20%;
  display: flex;
  justify-content: space-between;
}
.physicalReport__statText {
  font-weight: bold;
  font-size: 1rem;
}
.physicalReport__statValue {
  border: 1px solid var(--clr--primary);
  border-radius: 5px;
  color: var(--clr--accent);
  font-weight: bold;
  font-size: 1rem;
  flex-basis: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* media queries */
@media only screen and (max-width: 1060px) {
  .user-form-inputs,
  .asset-form-inputs {
    grid-template-columns: 1fr;
    /* border: 1px solid red; */
    width: 70%;
    margin: auto;
  }
  .form-check {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
  .user-permissions__body .form-check {
    flex-basis: 30%;
  }
  .form-check-input {
    position: static !important;
    top: 0 !important;
    margin-right: 0 !important;
  }

  .master-tab {
    flex-basis: 25%;
  }
}
@media only screen and (max-width: 850px) {
  .user-form-inputs,
  .asset-form-inputs {
    grid-template-columns: 1fr;
    /* border: 1px solid red; */
    width: 100%;
    margin: auto;
  }
  .assetForm-header {
    flex-direction: column;
    justify-content: space-around;
    gap: 1em;
    height: 15em;
  }
  .userManagement .input-group,
  .assetForm .input-group {
    flex-direction: column;
    gap: 0.5em;
  }
  .assetForm .asset-type .input-group {
    flex-direction: row;
  }
  .userManagement .input-group input,
  .assetForm .input-group input {
    width: 100%;
  }
  .user-permissions__body .form-check {
    flex-basis: 40%;
  }
  .password-icon-wrapper {
    top: 52% !important;
    right: 20% !important;
  }
  .asset-form-inputs-snd {
    flex-direction: column;
    gap: 1em;
  }

  .asset-dashboard-wrapper .tile {
    flex-basis: 30%;
  }

  .all-master-header {
    flex-direction: column;
  }
  .master-tab {
    flex-basis: 45%;
  }
}
@media only screen and (max-width: 640px) {
  .asset-dashboard-wrapper .tile {
    flex-basis: 90%;
  }
  .master-tab {
    flex-basis: 100%;
  }
}
@media only screen and (max-width: 460px) {
  .user-permissions__body .form-check {
    flex-basis: 50%;
  }
}
