.generic-modal {
  margin-top: 2em;
}
.generic-modal .modal-content {
  border: none;
}
.generic-modal .modal-header {
  border: none;
}
.generic-modal .modal-header,
.generic-modal .modal-footer {
  background-color: var(--clr--accent);
  padding: 0.75em !important;
}
.generic-modal .modal-footer {
  border-top: none;
  border-bottom: none;
}
.generic-modal .modal-body {
  max-height: 60vh;
  padding-bottom: 2em;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--bg--body);
}
.generic-modal .modal-title {
  color: var(--clr-neutral);
  font-weight: bold;
}
.generic-modal__close-icon--wrapper {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--clr--accent);
  position: absolute;
  top: -10px;
  right: -10px;
}
.generic-modal__close-icon {
  height: 36px;
  width: 36px;
  color: var(--clr-neutral);
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.generic-modal__close-icon:hover {
  scale: 1.1;
}

.generic-modal__btn {
  background-color: var(--clr-neutral);
  color: var(--clr--neutral);
  border: none;
  font-size: 1rem;
  cursor: pointer;
  width: 6rem;
}
.generic-modal__btn:hover {
  background-color: var(--clr-neutral-hover);
  color: var(--bg--body);
}
.generic-modal__btn + .generic-modal__btn {
  margin-left: 1em;
}

.modal__form {
  display: flex;
  gap: 2em;
  flex-flow: row wrap;
  margin: 1em;
}

.modal__form > div {
  flex-basis: var(--modalInputFlexBasis);
  flex-grow: 100;
  margin: 0;
}
.modal__form .reports__date-filter {
  /* flex-basis: 31%; */
  width: 30%;
}

.modal__form > div.addLineManager__desc {
  flex-grow: 100;
}
.modal__form > div > label {
  font-weight: bold;
}

.modal__form > div > label.required::after,
.required::after {
  content: " *";
  color: red;
}

.modal__form input,
.modal__form select,
.modal__form textarea,
.modal__form > table div > input {
  border: 1px solid var(--clr--accent);
  font-size: 1rem !important;
  background-color: transparent;
}

/* .modal__form > div > label {
  font-size: 0.8rem;
}
.modal__form > div > input::placeholder {
  font-size: 0.8rem;
} */
.modal__form input:focus,
.modal__form select:focus,
.modal__form textarea:focus,
.modal__form > table div > input:focus {
  border-color: var(--clr--accent);
  box-shadow: 0 0 3px var(--clr--accent);
  background-color: transparent;
}

.modal__form > div > select {
  display: block;
  height: 32px !important;
  font-size: 1rem !important;
}
.modal__form > div > select > option:checked,
.modal__form > div > select > option:hover {
  background-color: var(--clr--accent) !important;
}

.password-icon-wrapper {
  position: absolute;
  right: 6px;
  top: 40px;
}
.password-icon {
  color: var(--gray-500);

  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.password-icon:hover {
  color: var(--gray-600);
}

/*
.modal-content
    .modal-header
        .modal-title (h4)
    .modal-body
        .addLineManager__form (varies)
            div
                label (.form-label)
                input (form-control)/select (form-select) /div
    .modal-footer
*/

.modal-form__radio-btns {
  display: flex;
  gap: 1em;
}
.modal-form__radio-btns input[type="radio"]:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--bg--main);
  content: "";
  display: inline-block;
  visibility: visible;
}

.modal-form__radio-btns input[type="radio"]:checked:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--clr--accent);
  content: "";
  display: inline-block;
  visibility: visible;
}
