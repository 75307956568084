a {
  text-decoration: none;
}

a:hover {
  color: #ff7200;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--scrollbar-bg);
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--scrollbar-bg);
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--scrollbar-clr);
}

.tt2 {
  font-size: 1rem;
  font-weight: 600;
}

.btn-orange {
  background-color: #ff7200;
  color: #fff;
}

.btn-outline-orange {
  background-color: #fff;
  color: #ff7200;
  border: 1px solid #ff7200;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-outline-orange:hover {
  background-color: #ff7200;
  color: #fff;
}
.fCol {
  flex-direction: column;
}
.flex-algn-cent {
  display: flex;
  align-items: center;
}
.mAuto {
  margin: auto;
}
.aItemsCenter {
  align-items: center;
}
.space-between {
  justify-content: space-between;
}
.aSelfCenter {
  align-self: center;
}
.aSelfStart {
  align-self: flex-start;
}
.aSelfEnd {
  align-self: flex-end;
}
.jSelfCenter {
  justify-self: center;
}
.space-around {
  justify-content: space-around;
}
.flex-jc-end {
  display: flex;
  justify-content: flex-end;
}

.flex-jc-center {
  display: flex;
  justify-content: center;
}

.flex-inline {
  display: inline-flex !important;
}

.collapse-view {
  height: 35px;
  overflow: hidden;
  position: relative;
}

.collapse-view > .collapsable_icon {
  position: absolute;
  right: 15px;
  top: 0;
  height: 35px;
  display: flex;
  width: 50px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
}

.collapse-view > .collapsable_icon:after {
  content: "\002B";
  color: white;
  font-weight: bold;
}

.collapse-view.open {
  overflow: unset;
}

.collapse-view.open > .collapsable_icon:after {
  content: "\2212";
}

/* #region Contract Detail */
.contract-tbl {
  width: 100%;
}

.contract-tbl > table,
.contract-tbl1 > table {
  width: 100%;
  border: 1px solid #e5e5e5;
}

.contract-tbl > table,
.contract-tbl > table tr,
.contract-tbl > table td,
.contract-tbl > table th,
.contract-tbl1 > table,
.contract-tbl1 > table tr,
.contract-tbl1 > table td,
.contract-tbl1 > table th {
  border-collapse: collapse;
}

.contract-tbl > table th {
  padding: 10px 15px;
  background-color: #f2f2f2;
  font-weight: unset;
  font-size: 1rem;
  text-align: right;
  width: 15%;
  border-right: 1px solid #e5e5e5;
}

.contract-tbl1 > table th {
  padding: 10px 15px;
  background-color: #f2f2f2;
  font-weight: unset;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid #e5e5e5;
  color: #000;
}

.contract-tbl1 > table td {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #e5e5e5;
  color: #000;
}

.contract-tbl > table td {
  padding: 10px;
  width: 85%;
  color: #ff7200;
  font-weight: 600;
  font-size: 1rem;
}

.view_dc_btn {
  position: absolute;
  right: 10px;
  font-size: 0.8rem;
  font-weight: 100;
  text-decoration: underline;
  color: #2d8fb8;
  cursor: pointer;
}

.icncc {
  font-size: 1.6rem;
  margin-right: 0.5rem;
}
/* #endregion Contract Detail */

/*ddzone styling*/
#ddzone_drop-region {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 10px;
  text-align: center;
}

#ddzone_drop-region > .ddzone_drop-con {
  border: 2px dashed #727376;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  transition: 0.3s;
}

#ddzone_drop-region > .ddzone_drop-con:hover,
#ddzone_drop-region > .ddzone_drop-con.ddzone_hover {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.1);
}

#ddzone_drop-region > .ddzone_drop-con > img {
  width: 100px;
}

#ddzone_drop-region > .ddzone_drop-con > p {
  margin: 5px;
  font-weight: 600;
  color: #727376;
}

#ddzone_drop-region > .ddzone_drop-con > div {
  font-weight: 600;
  color: #727376;
}

#ddzone_image-preview {
  margin-top: 20px;
}

#ddzone_image-preview .ddzone_image-view {
  display: inline-block;
  position: relative;
  margin-right: 13px;
  margin-bottom: 13px;
}

#ddzone_image-preview .ddzone_image-view img {
  max-width: 100%;
}

#ddzone_image-preview .ddzone_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.5);
}
/*ddzone styling close*/

label.labelcc {
  font-weight: 600;
  width: 100%;
  margin-bottom: 0 !important;
  text-align: start;
}

.table-responsive {
  width: 100%;
  margin: 10px;
}

table.bordered_table {
  width: 100%;
}

table.bordered_table,
table.bordered_table th,
table.bordered_table td {
  border: 1px solid #727376;
  border-collapse: collapse;
  padding: 2px;
}

table.bordered_table th {
  padding: 5px;
  text-align: start;
}

table.bordered_table th > span.thd {
  font-size: 0.75rem;
  color: #888;
}

table.bordered_table th > h2.tht {
  font-size: 0.9rem;
  color: #3a3b3c;
  margin-bottom: 0;
}

.doc_container > .doc_container_head {
  height: 35px;
  background: #ff7200;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  font-size: 1rem;
}

.data-cont > .data-cont-item {
  position: relative;
  width: 100%;
  background: #727374;
  padding: 2px;
  border-radius: 5px;
  margin: 15px 0;
}

.data-cont > .data-cont-item > .sno_txt {
  position: absolute;
  background: #fff;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  top: -10px;
  left: -10px;
  border: 2px solid #727376;
  z-index: 1;
  font-size: 10px;
  font-weight: 600;
}

.data-cont > .data-cont-item > .data_item_content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  padding: 1px;
}

.data-cont > .data-cont-item > .data_item_content > div {
  width: calc(20% - 2px);
  background: #fff;
  padding: 5px;
  margin: 0 1px;
}
.data-cont > .data-cont-item > .data_item_content > .data_item_contnet_1 {
  width: 16.5%;
  background: #fff;
  padding: 5px;
  margin: 0 1px;
}

.data-cont > .data-cont-item > .data_item_content.set_height > div {
  height: 60px;
}

.data-cont > .data-cont-item > .data_item_content > div.wd_15 {
  width: calc(15% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_10 {
  width: calc(10% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_25 {
  width: calc(25% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_30 {
  width: calc(30% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_40 {
  width: calc(40% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_75 {
  width: calc(75% - 2px);
}

.data-cont > .data-cont-item > .data_item_content > div.wd_100 {
  width: calc(100% - 2px);
}

.black_color {
  color: #000 !important;
}

.orange_color {
  color: #ff7200 !important;
}

.grey_color {
  color: #727376 !important;
}

.green_color {
  color: green !important;
}

.red_color {
  color: red !important;
}

.white_color {
  color: #fff !important;
}

.font-bold {
  font-weight: 600 !important;
}

.font-sm {
  font-size: 0.7rem !important;
}

.font-xsm {
  font-size: 0.6rem !important;
}

.font-md {
  font-size: 0.8rem !important;
}

.font-lg {
  font-size: 1rem !important;
}

.font-xl {
  font-size: 1.2rem !important;
}

.font-xxl {
  font-size: 1.5rem !important;
}

.ccIcon {
  margin: 0 5px;
  font-size: 1.5rem;
  cursor: pointer;
}

/*#region Trip Analysis CSS*/
.bg_black {
  background: #4a4747;
  color: #fff;
}

.table-responsive {
  background: #fff;
  margin-bottom: 2rem;
  border-radius: calc(0.15rem - 1px);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}

.table-responsive > table.cctbl {
  width: 100%;
}

table.cctbl,
.cctbl td,
.cctbl tr,
.cctbl th {
  border: 1px solid #888;
  text-align: center;
  border-collapse: collapse;
}

.cctbl th {
  padding: 5px 0px;
  font-size: 16px;
}

table.cctbl td {
  vertical-align: top;
  width: 33.33%;
}

.dd_sec {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  margin: -2px;
  border-bottom: 1px solid #888;
  font-weight: 600;
  font-size: 1.3rem;
}

.dd_sec span {
  font-weight: 200;
  font-size: 11px;
  display: block;
  color: #fff !important;
}

.dd_sec > div {
  width: 100%;
  padding: 4px;
}

.dd_sec > div:first-child {
  border-right: 1px solid #888;
}

.dd_sec > div:last-child {
  border-left: 1px solid #888;
  margin-left: -1px;
}

.dd_sec.wt50 > div {
  width: 50% !important;
  padding: 0px;
}

.dd_sec div.btblock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dd_sec div.btblock > span {
  display: block;
  width: 50%;
}

.dd_sec > div.wt50 > div > div {
  width: 100%;
}

.dd_sec div.btblock > span.bold {
  font-weight: 600;
  font-size: 1.3rem;
  cursor: pointer !important;
  color: #fff;
  margin-right: 0;
}

.dd_sec:not(.wt50) div.btblock > span:not(.bold) {
  font-size: 0.9rem;
}

.dd_sec > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dd_sec > div > span {
  width: 50%;
}

.dd_sec > div > span.bold {
  font-weight: 600;
  font-size: 1.3rem;
  cursor: pointer;
  margin-right: 0;
}

.dd_sec > div .popup_ss {
  display: flex;
  position: relative;
  width: 100%;
  cursor: pointer;
  justify-content: center;
}

.dd_sec > div .popup_ss > .hh_sec {
  transform: translate(0%, 100%) !important;
  margin-bottom: -40px;
  position: absolute;
  height: fit-content;
  bottom: calc(100% + 10px);
  min-width: auto;
  max-width: 250px;
  font-size: 10px;
  margin-top: -40px;
  padding: 6px;
  color: #666666;
  background-color: #eeeeee;
  border-radius: 8px;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgb(0 0 0 / 50%);
  display: none;
  transition: all 0.8s ease-in-out;
  animation-duration: 0.6s;
  animation-name: fadeIn;
  font-weight: 600;
}

.dd_sec > div .popup_ss:hover > .hh_sec {
  display: block;
}

.dd_sec > div .popup_ss:hover > .hh_sec > i {
  position: absolute;
  bottom: -11px !important;
  left: calc(50% - 12px) !important;
  top: -11px;
  transform: rotate(0deg) !important;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.dd_sec > div .popup_ss:hover > .hh_sec > i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.dd_sec > div .popup_ss:hover > .hh_sec > div {
  border-bottom: 1px solid #888;
  color: #ff7200;
  font-size: 11px;
}

.dd_sec > div .popup_ss:hover > .hh_sec > p {
  font-size: 10px;
  line-height: normal;
  font-weight: 200;
  text-align: start;
  margin: 2px 0;
}

.dd_sec > div .popup_ss:hover > .hh_sec > p > b {
  font-weight: 600;
}

.dd_sec > div > .popup_ss span.bold {
  font-size: 12px;
}

.dd_sec > div > .popup_ss span.bold > *,
.dd_sec > div > .popup_ss span.bold {
  font-weight: 600 !important;
  font-size: 12px;
  cursor: pointer;
}

.dd_sec > div > div {
  padding: 2px;
  border-bottom: 1px solid #888;
  cursor: pointer;
}

.dd_sec > div > div:last-child {
  border-bottom: none;
}

.dd_sec.green {
  background: #228b22;
  color: #fff;
  margin: 0;
  border-color: #fff;
}

.dd_sec.green > div,
.dd_sec.green > div > div {
  border-color: #fff;
}

.dd_sec.yellow {
  background: #ffd300;
  color: #fff;
  margin: 0;
  border-color: #fff;
}

.dd_sec > div > div.yellow {
  background: #ffd300;
}

.dd_sec.yellow > div,
.dd_sec.yellow > div > div {
  border-color: #fff;
}

.dd_sec.purple {
  background: #800080;
  color: #fff;
  margin: 0;
  border-color: #fff;
}

.dd_sec > div > div.purple {
  background: #800080;
}

.dd_sec.purple > div,
.dd_sec.purple > div > div {
  border-color: #fff;
}

.dd_sec.grey {
  background: #888888;
  color: #fff;
  margin: 0;
  border-color: #fff;
}

.dd_sec > div > div.grey {
  background: #888888;
}

.dd_sec > div > div.green {
  background: #228b22;
}

.dd_sec.grey > div,
.dd_sec.grey > div > div {
  border-color: #fff;
}

.dd_sec.orange {
  background: #ff7200;
  color: #fff;
  margin: 0;
  border-color: #fff;
}

.dd_sec.orange > div,
.dd_sec.orange > div > div {
  border-color: #fff;
}

.no_cont {
  font-weight: 600;
  color: #808080;
  font-size: 16px;
}

/*#endregion Trip Analysis CSS*/

/*Ananlysis CSS*/

.ddtxt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ddtxt > i {
  transform: rotateY(180deg);
  font-size: 1.4rem;
  margin-right: 5px;
}

.analysis_flex1 {
  width: 100%;
  background: white;
  border-radius: 5px;
  height: 13rem;
}

.analysis_item1 {
  width: 100%;
  height: auto;
  padding: 15px 10px;
  position: relative;
}

.analysis_item1 > h3 {
  font-size: 1.3rem;
  font-weight: 600;
}

.analysis_item2 {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 5px;
  flex-wrap: wrap;
}

.analysis_item2 > div {
  background: #e5e5e5;
  width: calc(33.33% - 10px);
  padding: 10px 5px 10px 5px;
  border-radius: 5px;
  margin: 0 5px 5px 5px;
}

.adc22 {
  height: 50px;
  width: 100%;
  position: relative;
}

.adc22 .joining-line {
  position: absolute;
  height: 20px;
  border-right-color: #888;
  border-right-width: 2px;
  border-right-style: dotted;
  top: 12px;
  left: 7px;
}

.adc22 > h5::before {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #888;
  position: absolute;
  content: " ";
  left: -18px;
  top: 0px;
}

.adc22 h5 {
  margin-left: 20px;
  font-size: 0.8rem;
  position: relative;
  min-height: 30px;
  margin-bottom: 0;
}

.adc22 h5 > span {
  font-size: 0.9rem;
  display: block;
}

.adc22 h5 > p {
  font-size: 0.7rem;
  line-height: unset;
  margin-bottom: 0;
  color: #4a4646;
  font-family: GOTHIC;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
}

.pp1 > div {
  padding: 10px;
  border: 1px solid #ff7200;
}

.pp1 > div.no_brd_rht {
  border-right: none;
}

.ls10 {
  letter-spacing: 10px;
}

.ntc {
  bottom: 15px;
  left: 10%;
  position: absolute;
  height: 20px;
  width: 20px;
  border-left: 1px solid #ff7200;
  border-bottom: 1px solid #ff7200;
  background: #fff;
  transform: rotate(-45deg);
}

.analysis_dotted {
  display: flex;
  padding: 10px;
  text-align: center;
  font-size: 1rem;
}

.analysis_dotted :nth-of-type(1) {
  background-color: #fcc02d;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin: 5px 10px 0px 10px;
}

.analysis_dotted :nth-of-type(2) {
  background-color: #ff7200;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin: 5px 10px 0px 10px;
}

.analysis_dotted :nth-of-type(3) {
  background-color: #9026a7;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin: 5px 10px 0px 10px;
}

.analysis_dotted :nth-of-type(4) {
  background-color: #228622;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin: 5px 10px 0px 10px;
}

.analysis_icon {
  text-align: center;
  color: #228622;
  font-weight: bold;
  font-size: 15px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.analysis_icon i.glyph-icon {
  font-weight: 600;
  font-size: 1.2rem;
  margin-right: 5px;
}

.analysis_item {
  width: 100%;
  height: 25px;
  background: #ff7200;
  position: relative;
  display: flex;
  border-radius: 5px;
  margin-top: 40px;
}

.analysis_item > div {
  cursor: pointer;
  position: relative;
}

.analysis_item > div:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.analysis_item > div:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.analysis_item > div > div.popov {
  display: flex;
  position: relative;
  width: 100%;
  cursor: pointer;
  justify-content: center;
}

.analysis_item > div > div.popov > div.phub {
  z-index: 1;
  min-width: 150px;
  text-align: center;
  background-color: #ebe4e4;
  border-radius: 5px;
  font-size: 12px;
  display: none;
  position: absolute;
  bottom: 5px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.analysis_item > div:hover > div.popov > div.phub {
  display: block;
}

.analysis_item > div > div.popov.lft_algn > div.phub {
  left: 0;
}

.analysis_item > div > div.popov.lft_algn > div.phub {
  left: 0;
}

.analysis_item > div > div.popov > div.phub .ppart {
  border-bottom: 1px solid #ff7200;
  font-weight: bold;
}

.analysis_item > div > div.popov > div.phub .ppart span {
  color: #ff7200;
  background-color: #ebe4e4 !important;
  font-weight: bold;
  font-size: 12px !important;
}

.analysis_item > div > div.popov > div.phub .content {
  padding: 5px;
  font-size: 11px;
  word-wrap: break-word;
}

.analysis_item > div > div.popov > div.phub .content > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.analysis_item > div > div.popov > div.phub .content > div > div.dot-icon {
  height: 2px;
  width: 10px;
  position: relative;
  background: #3e3e3e;
  margin: 5px 10px;
}

.analysis_item
  > div
  > div.popov
  > div.phub
  .content
  > div
  > div.dot-icon::before,
.analysis_item
  > div
  > div.popov
  > div.phub
  .content
  > div
  > div.dot-icon::after {
  content: " ";
  height: 8px;
  width: 8px;
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -3px;
  border: 1px solid #3e3e3e;
  outline: none;
}

.analysis_item
  > div
  > div.popov
  > div.phub
  .content
  > div
  > div.dot-icon::before {
  left: -8px;
}

.analysis_item
  > div
  > div.popov
  > div.phub
  .content
  > div
  > div.dot-icon::after {
  right: -8px;
}

.analysis_item > div > div.popov > div.phub .lpart {
  background: #ff7200;
  text-align: center;
  font-size: 10px;
  color: #fff;
}

.analysis_item > div > div.popov > div.phub > i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #ff7200;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  z-index: -1;
  bottom: 0;
}

.analysis_item > div > div.popov.lft_algn > div.phub > i::after {
  left: 10%;
}

.analysis_item > div.yellow,
.analysis_item > div.yellow > div.popov > div.phub .lpart {
  background: #ffd300;
}

.analysis_item > div.yellow > div.popov > div.phub > i::after {
  background-color: #ffd300;
}

.analysis_item > div.yellow > div.popov > div.phub .ppart {
  border-bottom: 1px solid #ffd300;
}

.analysis_item > div.yellow > div.popov > div.phub .ppart span {
  color: #ffd300;
}

.analysis_item > div.green,
.analysis_item > div.green > div.popov > div.phub .lpart {
  background: #228b22;
}

.analysis_item > div.green > div.popov > div.phub > i::after {
  background-color: #228b22;
}

.analysis_item > div.green > div.popov > div.phub .ppart {
  border-bottom: 1px solid #228b22;
}

.analysis_item > div.green > div.popov > div.phub .ppart span {
  color: #228b22;
}

.analysis_item > div.purple,
.analysis_item > div.purple > div.popov > div.phub .lpart {
  background: #800080;
}

.analysis_item > div.purple > div.popov > div.phub > i::after {
  background-color: #800080;
}

.analysis_item > div.purple > div.popov > div.phub .ppart {
  border-bottom: 1px solid #800080;
}

.analysis_item > div.purple > div.popov > div.phub .ppart span {
  color: #800080;
}

.analysis_item > div.orange,
.analysis_item > div.orange > div.popov > div.phub .lpart {
  background: #ff7200;
}

.analysis_item > div.orange > div.popov > div.phub > i::after {
  background-color: #ff7200;
}

.analysis_item > div.orange > div.popov > div.phub .ppart {
  border-bottom: 1px solid #ff7200;
}

.analysis_item > div.orange > div.popov > div.phub .ppart span {
  color: #ff7200;
}

.analysis_item > div.orange + div.orange::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #727376;
}
/*Analysis CSS Clse*/

/* #region popover CSS*/
.popdv {
  display: inline-block;
  position: relative;
}

.popdv .btmplc {
  min-width: 300px;
  top: 30px;
  left: 50%;
  transform: translate(-90%, 0);
  padding: 6px;
  color: #666666;
  background-color: #eeeeee;
  font-weight: normal;
  font-size: 13px;
  border-radius: 2px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: none;
  transition: all 0.8s ease-in-out;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
}

.popdv .btmplc.top {
  transform: translate(-87%, -105%) !important;
  top: 0 !important;
  left: 0 !important;
}

.popdv .btmplc.top > i {
  bottom: -11px !important;
  left: 92% !important;
  transform: rotate(180deg) !important;
}

.popdv:hover .btmplc {
  display: block;
}

.popdv .btmplc > i {
  position: absolute;
  bottom: 100%;
  left: 90%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.popdv .btmplc > i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
/* #endregion popover CSS*/

/* #region Tracking UI*/
.contentmo {
  font-size: 13px;
  vertical-align: bottom;
  color: #ff7200;
  color: #8f8f8f;
  border: 1px solid #ff7200;
  border-radius: 20px 20px 0 0;
  padding-bottom: 5px;
}

.tt-title {
  text-align: center;
  border-bottom: 1px solid #ff7200;
  padding: 4px;
  font-size: 15px;
  line-height: unset;
}

.tt-title > label {
  color: #ff7200;
  margin-bottom: 0;
}

table.tt-cust {
  margin: 0px !important;
  padding: 0px !important;
  border-collapse: collapse;
  width: 100%;
}

table.tt-cust tr:nth-last-child(2) td .circle:before {
  border: 1px solid green;
}

table.tt-cust,
table.tt-cust tr,
table.tt-cust td {
  border: none;
}

table.tt-cust td {
  height: 80px;
  text-align: left;
  vertical-align: top;
  padding: 5px 0 !important;
  text-transform: none;
  position: relative;
  font-size: 11px;
}

table.tt-cust span {
  display: block;
  font-size: 10px;
  text-align: right;
}

.scrollmo {
  max-height: 350px;
  overflow: auto;
}

.scrollmo::-webkit-scrollbar {
  width: 5px;
  padding: 20px;
}

.scrollmo::-webkit-scrollbar-track {
  width: 5px;
  background-color: #f5f5f5;
  box-shadow: inset 0 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrollmo::-webkit-scrollbar-thumb {
  background-color: #ff7200;
  width: 5px;
  box-shadow: inset 0 0 0 6px rgba(0, 0, 0, 0.1);
}

#pl {
  padding-left: 15px !important;
}

table.tt-cust strong {
  color: rgb(37, 37, 37);
  font-weight: 600;
}

.greenmm td {
  color: green !important;
}

.greenmm strong {
  color: green !important;
}

.bt td {
  padding-top: 20px !important;
}

.circle {
  position: relative;
  border: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  line-height: 5px;
  margin-top: 0px;
  background-color: white;
  border: 3px solid #3e3e3e;
  z-index: 2;
  margin-left: 25px;
}

.circle:first-child {
  margin-top: 0;
}

div.midd::before {
  position: absolute;
  border: 1px solid #3e3e3e;
  width: 0;
  height: calc(100% - 25px);
  display: block;
  content: "";
  left: 50%;
  z-index: 1;
  top: 25px;
}

.greenmm .circle {
  border: 3px solid green;
}

.greenmm div.midd::before {
  display: none;
}
/* #endregion Close Tracking UI*/

/* #region Address Book UI*/
.book_content > .book_content_item {
  background: #f5f5f5;
  border-left: 3px solid #ff7200;
  padding: 5px 30px;
}

.book_content > .book_content_item table.book_tbl {
  width: 100%;
}

.book_content > .book_content_item table.book_tbl td {
  vertical-align: top;
  padding: 15px 20px;
  width: 30%;
}

.book_content > .book_content_item table.book_tbl td.wd25 {
  width: 20%;
}

.book_content > .book_content_item table.book_tbl td.actbt {
  width: 15%;
  padding: 0;
}

.book_content > .book_content_item table.book_tbl span.tbl_tt {
  display: block;
  margin-right: 0;
  color: #727376;
  position: relative;
  font-weight: 600;
}

.book_content > .book_content_item table.book_tbl span.tbl_tt > i {
  position: absolute;
  left: -20px;
  color: #ff7200;
  font-size: 1.3rem;
}

.book_content > .book_content_item table.book_tbl span.tbl_tt > img {
  position: absolute;
  left: -35px;
  top: -2px;
  height: 25px;
  width: 25px;
}

.book_content > .book_content_item table.book_tbl span.tbl_tt > i.ml30,
.book_content > .book_content_item table.book_tbl span.tbl_tt > img.ml30 {
  left: -30px;
}

.book_content > .book_content_item table.book_tbl span.tbl_txt {
  display: block;
  margin-right: 0;
  color: #a3a3a3;
  font-family: GOTHIC;
}

.book_content > .book_content_item table.book_tbl span.tbl_txt_sb {
  color: #a3a3a3;
  margin-right: 0;
  font-weight: 100;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px 0px;
  width: 100%;
  background-color: #fff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.button.wd10 {
  width: 10%;
}

.button.bg-orange-out {
  border: 1px solid #ff7200;
  color: #ff7200;
}

.button.bg-success-out {
  border: 1px solid #228b22;
  color: #228b22;
}

.button.bg-danger-out {
  border: 1px solid #d60606;
  color: #d60606;
}

.button.bg-grey-out {
  border: 1px solid #727376;
  color: #727376;
}

.button.bg-orange {
  background-color: #ff7200;
  color: #fff;
  border: 1px solid #ff7200;
}

.button.bg-success {
  background-color: #228b22;
  color: #fff;
  border: 1px solid #228b22;
}

.button.bg-danger {
  background-color: #d60606;
  color: #fff;
  border: 1px solid #d60606;
}

.button.bg-grey {
  background-color: #727376;
  color: #fff;
  border: 1px solid #727376;
}

.button.bg-orange-out:hover {
  color: #fff;
  background-color: #ff7200;
}

.button.bg-grey-out:hover {
  color: #fff;
  background-color: #727376;
}

.button.bg-success-out:hover {
  border-color: #fff;
  color: #fff;
  background-color: #228b22;
}

.button.bg-danger-out:hover {
  color: #fff;
  background-color: #d60606;
}

.button.bti > .btn_icon {
  width: 30%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button.bti.bg-orange > .btn_icon,
.button.bti.bg-success > .btn_icon,
.button.bti.bg-danger > .btn_icon,
.button.bti.bg-grey > .btn_icon {
  border-right: 1px solid #fff;
}

.button.bti.bg-orange-out > .btn_icon {
  border-right: 1px solid #ff7200;
}

.button.bti.bg-success-out > .btn_icon {
  border-right: 1px solid #228b22;
}

.button.bti.bg-danger-out > .btn_icon {
  border-right: 1px solid #d60606;
}

.button.bti.bg-grey-out > .btn_icon {
  border-right: 1px solid #727376;
}

.button.bti.bg-grey-out:hover > .btn_icon,
.button.bti.bg-orange-out:hover > .btn_icon,
.button.bti.bg-success-out:hover > .btn_icon,
.button.bti.bg-danger-out:hover > .btn_icon {
  border-color: #fff;
}

p.txtr {
  margin-bottom: 0;
  font-size: 1rem;
}

p.txtr > span {
  color: red;
}

.button > .btn_txt {
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: GOTHIC;
}

.button.bti > .btn_txt {
  width: 70%;
}
/* #endregion Address Book UI*/

/* #region Custom Input*/
input.cust-input,
select.cust-input {
  width: 100%;
  border: 1px solid #888;
  padding: 4px 8px;
  font-size: 1rem;
  color: #888;
}

input.cust-input:focus,
select.cust-input:focus {
  background-color: #fff;
  outline: 0;
  border-color: #ff7200;
  box-shadow: 0 0 0 0.2rem rgb(255 114 0 / 25%);
}

input.cust-input::placeholder {
  color: #959595;
}
/* #endregion Custom Input*/

/* #region Goods Receipt Detail */
.form-container {
  background-color: #727376;
  padding: 5px;
  width: 100%;
}

.custom-collapse {
  height: 30px;
  overflow: hidden;
  position: relative;
}

.custom-collapse.autoheight {
  height: auto;
}

.collapse-view.open {
  overflow: unset !important;
}

.custom-collapse:not(.autoheight):not(.open) > .customHead {
  border-bottom: 1px solid #fff;
}

.custom-collapse:not(.autoheight):not(.open) > .customBdy {
  display: none;
}

.custom-collapse > .customHead {
  background: #727376;
  color: #fff;
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 1.1rem;
  font-family: GOTHIC;
  padding: 0px 5px;
  justify-content: center;
  position: relative;
}

.custom-collapse > .customBdy {
  background-color: #fff;
  /* min-height: 1px; */
}

.custom-collapse > .customHead > .collapse_icon {
  position: absolute;
  right: 15px;
  top: 0;
  height: 30px;
  display: flex;
  width: 50px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
}

.custom-collapse > .customHead > .collapse_icon:after {
  content: "\002B";
  color: white;
  font-weight: bold;
}

.custom-collapse.open > .customHead > .collapse_icon:after {
  content: "\2212";
}

.tbl_div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
}

.tbl_div > .logo_sec_1 {
  width: 30%;
  height: inherit;
  text-align: center;
}

.tbl_div > .logo_sec_1 {
  width: 30%;
  height: inherit;
  text-align: center;
}

.tbl_div > .logo_sec_1 > img {
  max-width: 100%;
  max-height: 100%;
}

.tbl_div > .content_sec_1 {
  width: 70%;
  height: inherit;
  text-align: center;
}

.tbl_div > .content_sec_1 > p {
  margin-bottom: 0;
}

.brd_lft {
  border-left: 1px solid #727376;
}

.brd_rht {
  border-right: 1px solid #727376;
}

.brd_tp {
  border-top: 1px solid #727376;
}

.brd_btm {
  border-bottom: 1px solid #727376;
}

.brd_none {
  border: none;
}

.wdt10 {
  width: 10%;
}

.wdt20 {
  width: 20%;
}

.wdt5 {
  width: 5%;
}

.wdt25 {
  width: 25%;
}

.wdt15 {
  width: 15%;
}

.wdt30 {
  width: 30%;
}

.wdt40 {
  width: 40%;
}

.wdt45 {
  width: 45%;
}

.wdt50 {
  width: 50%;
}

.wdt55 {
  width: 55%;
}

.wdt60 {
  width: 60%;
}
.wdt70 {
  width: 70%;
}
.wdt80 {
  width: 80%;
}
.wdt90 {
  width: 90%;
}
.wdt100 {
  width: 100%;
}

.dv_flex {
  display: flex;
  position: relative;
}

.pd5 > div {
  padding: 5px !important;
  position: relative;
}

.pdm5 {
  padding: 5px !important;
}

input.nfc::placeholder {
  color: #959595;
  font-size: 0.75rem;
}

input.nfc:focus,
input.nfc:focus-visible,
select.nfc:focus,
select.nfc:focus-visible {
  outline: none !important;
}

.add_btn {
  background: #ff7200;
  color: #fff;
  padding: 2px 5px;
  cursor: pointer;
}

.check_cust {
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid #ff7200;
  background: #fff;
  cursor: pointer;
}

.check_cust.active::before {
  background: #ff7200;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.check_cust.active::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 8px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.cust_dd {
  display: inline-flex;
  width: 100px;
  top: 5px;
  position: absolute;
  margin-left: 5px;
}

.cust_dd.cc {
  top: -3px;
}

.cust_dd.cc .check_cust {
  border-radius: 0;
}

.cust_dd.cc .check_cust.active::before {
  border-radius: 0;
}

.cust_dd > label {
  margin-bottom: 0;
  margin-top: 1px;
  margin-left: 5px;
}

/* .required {
  color: red;
} */

.crsr-ptr {
  cursor: pointer !important;
}

.doc_attachment {
  position: absolute;
  background: #fff;
  width: auto;
  height: 20px;
  top: calc(50% - 10px);
  z-index: 999;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #727376;
  font-size: 0.8rem;
  border-radius: 3px;
  padding: 0 5px;
}

.doc_attachment i {
  font-size: 1rem;
  font-weight: 600;
  color: #888;
}

/* #endregion Goods Receipt Detail */

/* #region Analytics CSS */
.chartContainer {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
  height: 400px;
}

.chartContainer.ppchrt {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.chartContainer.ppchrt > h2 {
  position: absolute;
  top: -5px;
  left: 5px;
  font-weight: 600;
  background: #ff7200;
  color: #fff !important;
  padding: 5px;
}

.chartContainer.ppchrt > div.indicatorDv {
  position: absolute;
  bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.chartContainer.ppchrt > div.indicatorDv > div {
  width: 25%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chartContainer.ppchrt > div.indicatorDv > div > div {
  height: 30px;
  width: 30px;
  background-color: var(--bar-color);
}

.chartContainer.ppchrt > div.indicatorDv > div > label {
  width: calc(100% - 40px);
  margin-bottom: 0;
}

.chartContainer.ppchrt div.countdv {
  position: absolute;
  text-align: center;
  width: 100px;
  background: #727376;
  padding: 3px;
  color: #fff;
  border-radius: 5px;
  right: 30px;
  top: 30px;
}

.chartContainer > h1 {
  position: absolute;
  top: 0;
  left: 30px;
  font-size: 1.2rem;
}

.chartContainer > canvas {
  margin-top: 20px;
}

.chartCon {
  background: #fff;
  height: 300px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Line Chart CSS */
.css-chart {
  border-bottom: 2px solid #e5e5e5;
  border-left: 2px solid #e5e5e5;
  display: inline-block;
  height: var(--widget-size);
  padding: 0;
  position: relative;
  width: var(--widget-size);
}

.css-chart > .ylabel,
.grid > .ylabel {
  position: absolute;
  transform: rotate(-90deg);
  bottom: 50%;
  width: 100%;
  left: -60%;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
}

.grid > .ylabel {
  left: -54%;
  transform: rotate(90deg);
}

.css-chart > .xlabel,
.grid > .xlabel {
  position: absolute;
  bottom: -18px;
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 10px;
}

.grid > .xlabel {
  top: -25px;
}

.css-chart::before {
  width: 0;
  height: 0;
  border-bottom: 8px solid #e5e5e5;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: "";
  position: absolute;
  left: -6px;
  top: -6px;
}
.css-chart::after {
  width: 0;
  height: 0;
  border-left: 8px solid #e5e5e5;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  content: "";
  position: absolute;
  right: -7px;
  bottom: -6px;
}

.line-chart {
  list-style: none;
  margin: 0;
  padding: 0;
}

.data-point {
  background-color: #707070;
  border-radius: 50%;
  height: 12px;
  position: absolute;
  width: 12px;
  z-index: 1;
  cursor: pointer;
}

.data-point > .cConte {
  position: absolute;
  left: -30px;
  top: 20px;
  text-align: center;
  width: 80px;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 5px;
  font-weight: 600;
  background-color: #c7c6c6;
  display: none;
}

.data-point:hover > .cConte {
  display: block;
}

.data-point > .cConte::after {
  width: 0;
  height: 0;
  border-bottom: 10px solid #c7c6c6;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: "";
  position: absolute;
  left: 30px;
  top: -8px;
}

.line-segment {
  background-color: #ff7200;
  height: 1px;
  position: absolute;
  transform-origin: left bottom;
}

.labelsegment > ul {
  margin-bottom: 0;
  padding: 0;
  width: 100%;
}

.labelsegment > ul > li {
  display: inline-block;
  list-style-type: none;
  width: 48px;
  text-align: left;
  position: relative;
}

.labelsegment > ul > li::before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #e5e5e5;
  position: absolute;
  top: -5px;
  left: 12px;
}

.ulabelsegment > ul {
  margin-bottom: 0;
  padding: 0;
  width: 100%;
}

.ulabelsegment > ul > li {
  display: inline-block;
  list-style-type: none;
  width: 48px;
  text-align: left;
  position: relative;
}

.ulabelsegment > ul > li::before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #e5e5e5;
  position: absolute;
  top: -5px;
  left: 12px;
}

.contdv {
  position: absolute;
  right: 32px;
}

.floating_line {
  position: absolute;
  height: 0;
  width: 100%;
  border-bottom: 1px solid #888;
}

.chart-wrap {
  height: 400px;
  width: 300px;
}
.chart-wrap .title {
  font-weight: bold;
  font-size: 1.62em;
  padding: 0.5em 0 1.8em 0;
  text-align: center;
  white-space: nowrap;
}
.chart-wrap.vertical .grid {
  transform: rotate(-90deg);
}
.chart-wrap.vertical .grid .bar::after {
  transform: translateY(-50%) rotate(90deg);
  display: block;
}
.chart-wrap.vertical .grid::before,
.chart-wrap.vertical .grid::after {
  transform: translateX(-0.2em) rotate(90deg);
}
.chart-wrap .grid {
  position: relative;
  padding: 5px 0 5px 0;
  height: 100%;
  width: 100%;
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}
.chart-wrap .grid::before {
  width: 0;
  height: 0;
  border-bottom: 8px solid #e6e6e6;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: "";
  position: absolute;
  right: -8px;
  top: -5px;
}
.chart-wrap .grid::after {
  width: 0;
  height: 0;
  border-left: 8px solid #e5e5e5;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  content: "";
  position: absolute;
  left: -2px;
  bottom: -6px;
}
.chart-wrap .bar {
  /* width: var(--bar-width); */
  height: 10px;
  margin: 20px 0;
  background-color: var(--bar-color);
  border-radius: 0 3px 3px 0;
  position: relative;
}
.chart-wrap .bar:hover {
  opacity: 0.7;
}
.chart-wrap .bar::after {
  content: attr(data-name);
  margin-left: 100%;
  padding: 10px;
  display: inline-block;
  white-space: nowrap;
}

.chart-wrap .bar::before {
  content: attr(data-lbl);
  white-space: nowrap;
  position: absolute;
  left: -35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  top: 0;
}

.chart-wrap.vertical .grid .bar::before {
  transform: rotate(90deg);
}

.chart-wrap .bar.lbl::before {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  transform: rotate(90deg);
  left: -35px;
  width: 50px;
  font-weight: 600;
}

.chart-wrap .bar.lbl.fcc::before {
  font-size: 10px;
}

/* #endregion Analytics CSS*/

/* #region Donut chart CSS*/
.donut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}

.donut-chart .slice {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.donut-chart .chart-center {
  position: absolute;
  border-radius: 50%;
}

.donut-chart .chart-center span {
  display: block;
  text-align: center;
}

.donut-chart.chart2 {
  width: 120px;
  height: 120px;
  background: #e1e1e1;
}

.donut-chart.chart2 .slice.one {
  clip: rect(0 120px 60px 0);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.donut-chart.chart2 .slice.two {
  clip: rect(0 60px 120px 0);
  -webkit-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}

.donut-chart.chart2 .chart-center {
  top: 10px;
  left: 10px;
  width: 100px;
  height: 100px;
  background: #fff;
}

.donut-chart.chart2 .chart-center span {
  font-size: 30px;
  line-height: 100px;
  color: #48b2c1;
}

.donut-chart.chart2 .chart-center span:after {
  content: "91%";
}

.lbal {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.lbal > .lbl_btn {
  width: fit-content;
  padding: 2px 10px;
  color: #fff;
  display: inline-flex;
  font-size: 14px;
}

.lbl1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lbl1 > div.tt {
  width: 60px;
  position: relative;
  color: #000;
  font-size: 12px;
  font-weight: 600;
}

.lbl1 > div.tt::before {
  content: " ";
  height: 16px;
  width: 16px;
  position: absolute;
  left: -20px;
}

.lbl1 > div.tt.cc1::before {
  background: #ffd300;
}

.lbl1 > div.tt.cc2::before {
  background: #ff7200;
}

.lbl1 > div.tt.cc3::before {
  background: #800080;
}

.lbl1 > div.tt.cc4::before {
  background: #228b22;
}

.dntc::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: 6px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
}

.chart-orange .donut-chart.chart2 .slice.one,
.chart-orange .donut-chart.chart2 .slice.two,
.chart-orange .dntc::after,
.chart-orange .lbal > .lbl_btn {
  background-color: #ff7200;
}

.chart-orange .donut-chart.chart2 .chart-center span {
  color: #ff7200;
}

.chart-grey .donut-chart.chart2 .slice.one,
.chart-grey .donut-chart.chart2 .slice.two,
.chart-grey .dntc::after,
.chart-grey .lbal > .lbl_btn {
  background-color: #727376;
}

.chart-grey .donut-chart.chart2 .chart-center span {
  color: #727376;
}

.chart-blue .donut-chart.chart2 .slice.one,
.chart-blue .donut-chart.chart2 .slice.two,
.chart-blue .dntc::after,
.chart-blue .lbal > .lbl_btn {
  background-color: #225079;
}

.chart-blue .donut-chart.chart2 .chart-center span {
  color: #225079;
}

.chart-lg .donut-chart.chart2 .slice.one,
.chart-lg .donut-chart.chart2 .slice.two,
.chart-lg .dntc::after,
.chart-lg .lbal > .lbl_btn {
  background-color: #b9d874;
}

.chart-lg .donut-chart.chart2 .chart-center span {
  color: #b9d874;
}

.chart-lb .donut-chart.chart2 .slice.one,
.chart-lb .donut-chart.chart2 .slice.two,
.chart-lb .dntc::after,
.chart-lb .lbal > .lbl_btn {
  background-color: #46c1c1;
}

.chart-lb .donut-chart.chart2 .chart-center span {
  color: #46c1c1;
}
/* #endregion Donut Chart CSS*/

/* #region Pie Chart*/
@property --p {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.pie {
  --p: 20;
  --b: 22px;
  --c: darkred;
  --w: 150px;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
}

.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.pie:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
      var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}

.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg))
    translateY(calc(50% - var(--w) / 2));
}

.animate-chart {
  animation: p 1s 0.5s both;
}

.no-round:before {
  background-size: 0 0, auto;
}

.no-round:after {
  content: none;
}

@keyframes p {
  from {
    --p: 0;
  }
}

.chartlb {
  position: relative;
  width: 100%;
}

.chartlb > .chartlb-item {
  position: relative;
  margin: 5px;
}

.chartlb > .chartlb-item > .chartlb-ccbox {
  --bg: #ff7200;
  background-color: var(--bg);
  height: 20px;
  width: 20px;
  position: relative;
}

.chartlb > .chartlb-item > .chartlb-txt {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  width: calc(100% - 20px);
  padding: 0 10px;
}

/* #endregion Pie Chart*/

/* #region Vehicle Detail*/
.vd_cont {
  display: flex;
  width: 100%;
}

.vd_cont *:not(.fa) {
  font-family: GOTHIC;
}

.vd_cont > .vd_cont_1 {
  width: 30%;
}

.vd_cont > .vd_cont_tbl {
  width: 50%;
  padding: 10px;
  border: 2px solid #e3e3e3;
  position: relative;
}

.vd_cont > .vd_cont_1 > .vd_cont_1_sec {
  padding: 10px;
  text-align: center;
}

.vd_cont > .vd_cont_1 > .vd_cont_1_sec > .vd_cont_1_sec_icon {
  padding: 5px 10px;
  font-size: 2rem;
  color: #ff7200;
}

.vd_cont > .vd_cont_1 > .vd_cont_1_sec > .vd_cont_1_sec_icon {
  padding: 5px 10px;
  font-size: 2rem;
  color: #ff7200;
}

.vd_cont > .vd_cont_1 > .vd_cont_1_sec > .vd_cont_1_sec_tt {
  font-size: 1rem;
  color: #959595;
}

.vd_cont > .vd_cont_1 > .vd_cont_1_sec > .vd_cont_1_sec_txt {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
}

.vd_cont > .vd_cont_2,
.vd_cont > .vd_cont_3 {
  width: 35%;
  padding: 10px;
  border: 2px solid #e3e3e3;
  position: relative;
}

.vd_cont > .vd_cont_2,
.vd_cont > .vd_cont_tbl.brtd {
  border-right: none;
}

.vd_cont > .vd_cont_2::after,
.vd_cont > .vd_cont_tbl.brtd::after {
  content: "";
  position: absolute;
  right: -1px;
  width: 0;
  top: 10px;
  height: calc(100% - 20px);
  border: 1px dashed #e3e3e3;
}

.vd_cont > .vd_cont_3,
.vd_cont > .no_btr {
  border-left: none;
}

.vd_cont > .vd_cont_2 > .vd_cont_2_sec,
.vd_cont > .vd_cont_tbl > .vd_cont_tbl_sec {
  width: 100%;
  display: flex;
  padding: 5px;
}

.vd_cont > .vd_cont_2 > .vd_cont_2_sec + .vd_cont_2_sec,
.vd_cont > .vd_cont_tbl > .vd_cont_tbl_sec + .vd_cont_tbl_sec {
  border-top: 2px solid #e3e3e3;
  margin-top: 5px;
}

.vd_cont > .vd_cont_2 > .vd_cont_2_sec > .vd_cont_2_sec_1,
.vd_cont > .vd_cont_tbl > .vd_cont_tbl_sec > .vd_cont_tbl_sec_1 {
  font-size: 1rem;
  color: #959595;
  width: 50%;
}

.vd_cont > .vd_cont_2 > .vd_cont_2_sec > .vd_cont_2_sec_2,
.vd_cont > .vd_cont_tbl > .vd_cont_tbl_sec > .vd_cont_tbl_sec_2 {
  font-size: 1rem;
  color: #ff7200;
  width: 50%;
  text-align: end;
}

.vd_cont > .vd_cont_3 > .vd_cont_3_sec {
  position: relative;
  border: 1px solid #e3e3e3;
}

.vd_cont > .vd_cont_3 > .vd_cont_3_sec > .vd_cont_3_sec_header {
  background: #ff7200;
  color: #fff;
  padding: 8px;
  font-size: 1rem;
}

.vd_cont > .vd_cont_3 > .vd_cont_3_sec > .vd_cont_3_sec_bdy {
  padding: 8px;
}

.vd_cont > .vd_cont_3 > .vd_cont_3_sec > .vd_cont_3_sec_bdy > .btn_cont {
  width: 50%;
  margin: 10px 25% 10px 25%;
}

.vd_cont
  > .vd_cont_3
  > .vd_cont_3_sec
  > .vd_cont_3_sec_bdy
  > .file_preview
  > .file_preview_cont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.vd_cont
  > .vd_cont_3
  > .vd_cont_3_sec
  > .vd_cont_3_sec_bdy
  > .file_preview
  > .file_preview_cont
  > .preview_itm {
  width: calc(25% - 10px);
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 5px;
  border: 1px solid #e3e3e3;
}

.vd_cont > .vd_cont_3 > .vd_cont_3_sec > .vd_cont_3_sec_bdy > .file_preview {
  padding: 10px;
}

.vd_cont
  > .vd_cont_3
  > .vd_cont_3_sec
  > .vd_cont_3_sec_bdy
  > .file_preview
  > p {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
}

.vd_cont
  > .vd_cont_3
  > .vd_cont_3_sec
  > .vd_cont_3_sec_bdy
  > .file_preview
  > .file_preview_cont
  > .preview_itm
  > img {
  max-width: 100%;
  max-height: 100%;
}

.vd_cont
  > .vd_cont_3
  > .vd_cont_3_sec
  > .vd_cont_3_sec_bdy
  > .file_preview
  > .file_preview_cont
  > .preview_itm
  > .closeb {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #e3e3e3;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fullscreen {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #888;
  position: absolute;
  z-index: 9999;
  bottom: 2px;
  right: 2px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.fullscreen > i {
  font-weight: 600;
  color: #888;
}

/* #endregion Vehicle Detail*/
.form-control {
  padding: 5px !important;
  border-radius: 5px !important;
  font-size: 0.8rem !important;
}

.data-cont > .data-cont-item > .data_item_content > div.btn_conte {
  width: 100%;
  background: #fff;
  margin: 2px 1px;
  text-align: center;
  height: auto;
}

.data-cont > .data-cont-item > .data_item_content > div i.copyIcon {
  cursor: pointer;
  font-weight: 600;
  color: #888;
}

.data-cont > .data-cont-item > .data_item_content > div > span {
  display: block;
}

.data-cont > .data-cont-item > .data_item_content > div > span:nth-child(1) {
  font-size: 0.6rem;
  color: #888;
  font-weight: 600;
}

.data-cont > .data-cont-item > .data_item_content > div > span:nth-child(2) {
  font-size: 0.75rem;
  font-weight: 600;
  color: #000;
}

.data-cont > .data-cont-item > .data_item_content > div > span:nth-child(3) {
  font-size: 0.65rem;
}

.data-cont > .data-cont-item > .data_item_content > div > span.flex_box_1 {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 100%;
  margin-bottom: -5px;
}

.data-cont > .data-cont-item > .data_item_content > div > span.flex_box_2 {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-inline-box;
  overflow: hidden;
  width: 100%;
  margin-bottom: -5px;
}

.data-cont > .data-cont-item > .data_item_content.clps {
  display: none;
  /* animation-duration: 0.3s;
    animation-name: slideDown; */
}

.data-cont > .data-cont-item.open > .data_item_content.clps {
  display: flex;
}

.data-cont > .data-cont-item > div.collps_icon {
  position: absolute;
  display: flex;
  height: 20px;
  width: 40px;
  background: #727376;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  right: 4px;
  bottom: 3px;
  cursor: pointer;
  border-top-left-radius: 2px;
}

.data-cont > .data-cont-item > div.collps_icon > i {
  font-size: 1.5rem;
  color: #fff;
  margin-top: -4px;
}

.data-cont > .data-cont-item.open > div.collps_icon > i {
  margin-top: 0;
  margin-bottom: -4px;
  opacity: 0;
  animation-duration: 0.3s;
  animation-name: rotateAnimation;
  animation-fill-mode: forwards;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotateAnimation {
  0% {
    opacity: 0;
    transform: rotate(0);
  }
  100% {
    opacity: 1;
    transform: rotate(180deg);
  }
}

.headerpanel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background-color: #fff;
  z-index: 2000;
}

.headerpanel .logopanel {
  position: absolute;
  left: 0px;
  width: 220px;
  height: inherit;
  background-color: #fff;
  display: grid;
  place-content: center;
}

.headerpanel .logopanel > h2 {
  margin-top: 10px;
}

.headerpanel .logopanel > h2 img {
  height: 50px;
}

.headerbar {
  margin-left: 220px;
  position: relative;
  padding-left: 64px;
  left: 0 !important;
}

.menutoggle {
  position: absolute;
  width: 64px;
  height: 64px;
  top: 0;
  left: 0;
  font-size: 21px;
  cursor: pointer;
  color: var(--clr--primary);
  transition: all 0.2s;
}

.menutoggle i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -11px;
  margin-left: -10px;
}

.header-right {
  float: right;
  padding-right: 20px;
  height: 64px;
}

.headermenu {
  list-style: none;
  margin: 0;
  padding: 0;
  height: inherit;
}

.headermenu > li {
  display: inline-block;
  position: relative;
  height: inherit;
}

.headermenu > li > .btn-group {
  height: 64px;
  display: grid;
  place-content: center;
  min-width: 120px;
}

.headermenu > li > .btn-group > .btn-logged {
  background: transparent;
  border: none;
  color: #727376;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headermenu > li > .btn-group > .btn-logged div.udeta {
  padding: 0 5px;
}

.headermenu > li > .btn-group > .btn-logged div.udeta > span {
  display: block;
  text-align: center;
}

.headermenu > li > .btn-group > .btn-logged div.udeta > span.n1 {
  font-size: 1rem;
  color: var(--clr--accent);
  margin-bottom: -5px;
}

.headermenu > li > .btn-group > .btn-logged div.udeta > span.n2 {
  font-size: 0.8rem;
  color: var(--clr--primary);
}

.headermenu > li > .btn-group > .btn-logged div.udeta > span.n3 {
  font-size: 0.6rem;
  color: #727376;
}

.headermenu > li > .btn-group > .btn-logged:focus,
.headermenu > li > .btn-group > .btn-logged:active {
  outline: none !important;
  border: none !important;
  box-shadow: none;
}

.headermenu > li > .btn-group > .btn-logged img {
  vertical-align: middle;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50px;
}

.footer::before {
  content: "";
  width: 100%;
  height: 10px;
  position: absolute;
  background: #143a4d;
  top: -10px;
}

.footer > p::before {
  content: "";
  height: 10px;
}

.footer > p {
  color: #fff;
  margin-bottom: 0rem;
}

.footer {
  background: #061f2b;
  position: relative;
  margin-top: 10px;
  height: 40px;
  display: grid;
  place-content: center;
}

.caret {
  cursor: pointer;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  /* border-top: 4px dashed; */
  border-top: 10px solid var(--clr--accent);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.main-content {
  min-height: 100vh;
  font-family: GOTHIC;
  font-size: 12px;
  line-height: 1.42857143;
  /* color: #696c74; */
  /* background-color: #d8dce3; */
}

.nav-quirk > li.nav-parent > a:after {
  content: "\f107";
  font-family: "FontAwesome";
  font-size: 16px;
  font-weight: 400;
  color: #bdc3d1;
  position: absolute;
  top: 10px;
  right: 15px;
}

.leftpanel-profile {
  padding: 15px;
  background-color: #3b4354;
  margin-top: 0;
}

.media,
.media-body {
  zoom: 1;
  overflow: hidden;
}

.media-left,
.media-right,
.media-body {
  display: table-cell;
  vertical-align: top;
}

.leftpanel-profile .media-object {
  width: 48px;
  height: 48px;
  padding: 3px;
  border: 2px solid #657390;
}

.leftpanel-profile .media-left {
  padding-right: 15px;
}

.media-object {
  display: block;
}

.media-body {
  width: 9992px;
}

.img-circle {
  border-radius: 50%;
}

img {
  vertical-align: middle;
}

.leftpanel-profile .media-body {
  color: rgba(255, 255, 255, 0.5);
}

.leftpanel-profile .media-heading {
  margin: 7px 0 2px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
  position: relative;
}

.leftpanel-profile .media-heading > .caret {
  margin-top: 8px;
}

.btn-group.open > .user-dropmenu,
.media.open > .user-dropmenu {
  display: block;
}

.user-dropmenu {
  z-index: 99;
  display: none;
  width: 100%;
  top: 50px;
  right: 15px;
  position: absolute;
  border-color: rgba(38, 43, 54, 0.1);
  border-top: 0;
  margin-top: 0;
  margin-right: -8px;
  padding: 0px;
  background-color: var(--bg--body);
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  transition: all 0.2s ease-out 0s;
}

.user-dropmenu::before {
  border-bottom: 8px solid var(--bg--main);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: "";
  display: inline-block;
  right: 5px;
  position: absolute;
  top: -7px;
}

.user-dropmenu > ul {
  list-style: none;
  padding: 5px;
  margin: 4px;
  background-color: var(--bg--body);
}

.user-dropmenu > ul > li {
  font-size: 15px;
  font-weight: 600;
  color: #f57421;
  cursor: pointer;
  padding: 5px;
  transition: all 0.2s;
}

.user-dropmenu > ul > li:hover {
  background-color: #f57421;
  color: #fff;
}

.user-dropmenu > ul > li > i {
  margin-right: 5px;
}

.main-content > .content-body {
  padding-top: 64px;
  margin-left: 0px;
  transition: all 0.4s;
  overflow-x: hidden;
}

.main-content > .content-body > .contentpanel {
  margin: 8px 15px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 10px;
  height: 82vh;
  transition: all 0.4s;
  overflow-x: auto;
}

.sidebar.open + .content-body {
  margin-left: 180px;
}

.sidebar-title {
  text-transform: uppercase;
  font-size: 11px;
  color: #9fa8bc;
  line-height: normal;
  letter-spacing: 0.5px;
  padding-left: 18px;
  font-weight: 600;
  margin: 10px 0;
}

.cust-breadcrumb {
  padding: 8px 26px;
  list-style: none;
  background-color: #fff;
  border-radius: 2px;
  margin: 5px 15px;
}

.cust-breadcrumb > li {
  font-size: 12px;
  display: inline-block;
}

.cust-breadcrumb > li > a {
  color: #545b68;
}

.cust-breadcrumb > li + li:before {
  content: "\f105";
  font-family: "FontAwesome";
  color: #818da7;
  margin-right: 5px;
  margin-left: 5px;
}

.cust-breadcrumb > li.active {
  color: #464f63;
  font-weight: 500;
}

.cust-breadcrumb > li.active > a {
  pointer-events: none;
}

.contentpanel h1.title {
  font-size: 1.2rem;
  margin: 0;
}

.contentpanel h1.title ~ hr,
hr.bgg {
  margin-top: 0.1rem;
  margin-bottom: 0.5rem;
}

.cust-container {
  width: inherit;
  position: relative;
  padding: 5px 10px;
}

.cust-row {
  display: flex;
  width: 100%;
  position: relative;
  /* flex-wrap: wrap; */
}

.cust-row > .cust-col-10 {
  width: 100%;
  position: relative;
  padding: 5px 10px;
}

.ttname {
  font-size: 0.8rem;
  color: #000;
  font-weight: 600;
  display: block;
  width: 100%;
  position: absolute;
  top: -25px;
  left: 15px;
}

.ttname .transportrName {
  font-size: 1.1rem;
  color: #ff7200;
  font-weight: 600;
  padding-left: 6px;
  text-decoration: underline;
  text-underline-position: under;
}

.cust-row > .cust-col-5 {
  width: 50%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-2 {
  width: 20%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-4 {
  width: 40%;
  position: relative;
  padding: 5px 10px;
}
.cust-row > .cust-col-4-new {
  width: 35%;
  position: relative;
  padding: 5px 10px;
  /* background-color: black; */
}

.cust-row > .cust-col-9 {
  width: 90%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-8 {
  width: 80%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-7 {
  width: 70%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-6 {
  width: 60%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-3 {
  width: 30%;
  position: relative;
  padding: 5px 10px;
}

.cust-row > .cust-col-1 {
  width: 10%;
  position: relative;
  padding: 5px 10px;
}

.filed-cont {
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
}

.filed-cont > .icon-cont {
  width: 20%;
  height: 100%;
  top: 0;
  bottom: 0;
  display: grid;
  justify-content: center;
  place-items: center;
}

.filed-cont > .icon-cont > i {
  font-size: 3rem;
}

.filed-cont > .data-cont {
  width: 80%;
  height: 100%;
  display: grid;
  align-items: center;
  padding-left: 10px;
}

.filed-cont.doc-cont > .data-cont {
  width: 80%;
  display: block;
  margin-left: 10px;
}

.filed-cont.doc-cont > .icon-cont {
  width: 10%;
  display: block;
}

.filed-cont > .data-cont > label {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  color: #736e6e;
  margin-bottom: 0rem;
}

.filed-cont > .data-cont > input,
.table-row > h3 > input {
  width: 100%;
  font-size: 14px;
  border: 1px solid #736e6e;
  border-radius: 5px;
  padding: 2px 5px;
  color: #736e6e;
}

.table-row > h3 > input {
  width: 95%;
  height: 30px;
}

.filed-cont > .data-cont > input:focus,
.table-row > h3 > input:focus {
  outline: none;
  border-color: #f57421;
  color: #f57421;
}

.filed-cont > .data-cont > div,
.table-row > h3 > div {
  font-size: 15px;
  color: #696c74;
}

.filed-cont > .data-cont > div > span,
.table-row > h3 > div > span {
  color: #fff;
  background: #8c8e92;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}

.fa.fa_new {
  height: 45px;
  width: 45px;
  display: grid;
  justify-content: center;
  align-items: center;
  background: #696c74;
  color: #fff;
  border-radius: 50%;
  font-size: 2rem !important;
  font-family: FontAwesome;
}

.fa-char-i::before {
  content: "\0049";
}

.fa-char-g::before {
  content: "\0047";
}

.fa-char-c::before {
  content: "\0043";
}

.fa-char-p::before {
  content: "\0050";
}

.profile-title {
  font-size: 1rem;
  margin: 0;
  padding-left: 15px;
  text-transform: uppercase;
  color: #7d7d7c;
}

.image-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-holder > img {
  border: 2px solid #eaeaea;
  padding: 10px;
  height: 300px;
  width: 300px;
}

.image-holder > label {
  position: absolute;
}

label.sr-file {
  color: #fff;
  background: #8c8e92;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}

.image-holder > label.sr-file {
  position: absolute;
  height: 40px;
  width: 40px;
  bottom: 35px;
  right: 68px;
}

.image-holder > label.sr-file > i {
  font-size: 1.2rem;
}

.custmr-5 {
  margin-bottom: 15px;
}

i.verified {
  color: #249c0e;
}

label.sr-file > input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.save_btn {
  float: right;
  background: #f57421;
  color: #fff;
  font-size: 15px;
  padding: 5px 10px;
  margin-top: -5px;
  border-radius: 5px;
  cursor: pointer;
}

.cust-card {
  border: none;
  background: #fff;
  border-radius: calc(0.15rem - 1px);
  box-shadow: 0px 0px 5px #f57421;
  width: 100%;
  height: 250px;
}

.cust-card > .cust-card-body {
  text-align: center;
}

.cust-card > .cust-card-body > img {
  height: 100px;
  margin-top: 12%;
  user-select: none;
}

.cust-card > .cust-card-body > h3 {
  margin: 1rem 0;
  user-select: none;
  font-size: 1.5rem;
}

.cust-card > .cust-card-body > button {
  background: #f57421;
  border: #f57421;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

.cust-card > .cust-card-body > button:focus,
.cust-card > .cust-card-body > button:hover {
  outline: none;
  box-shadow: 2px 2px 10px #ff7200;
}

.table-row {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #888;
  height: 42px;
}

.table-row > h4,
.table-row > h3 {
  font-size: 15px;
  width: 50%;
  position: relative;
  margin-bottom: 0;
}

.table-row > h4 {
  text-align: left;
}

.table-row > h3 > .danger {
  color: #ea6060;
}

.table-row > h3 > .verified {
  color: #47883b;
}

.table-row > h4::after {
  content: ":";
  position: absolute;
  right: 0;
}

.table-row > h3 > label.sr-file {
  height: 30px;
  width: 30px;
  margin-top: 5px;
}

.pd10 {
  padding: 10px;
}

.ctct {
  margin-top: 20px;
  float: left;
}

.view_btn {
  background: #259cab;
  border: #259cab;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.view_btn:focus {
  outline: none;
}

button:disabled {
  cursor: not-allowed !important;
  opacity: 0.8;
  box-shadow: none !important;
}

.share-div {
  width: 100% !important;
}

.share-div > label {
  text-align: left;
  width: 95%;
  font-size: 15px;
  font-family: GOTHIC;
}

.custom-modal-body > .row-div > div > div.custom-group {
  display: flex;
  justify-content: center;
}

.custom-modal-body > .row-div > div > div.custom-group > .control-input {
  height: 35px;
  width: 87%;
  border-radius: 5px;
  border: 1px solid #f6ac49;
  padding: 0 5px;
  outline: none;
  caret-color: #f6ac49;
  color: #f6ac49;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.custom-modal-body > .row-div > div > div.custom-group > .append-icon {
  width: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e8a13f;
  color: #fff;
  cursor: pointer;
}

.doc_cont {
  text-align: initial;
  width: 95%;
  margin-left: 2.5%;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 10px;
}

.doc_cont > div {
  width: 100px;
  background: #f57421;
  color: #fff;
  text-align: center;
  padding: 4px 0px;
  margin: 0px 10px 5px 10px;
}

.doc_cont > div > i {
  cursor: pointer;
  margin: 0 10px;
}

.msgPara {
  font-family: GOTHIC;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
}

table > tbody > tr > * {
  font-family: GOTHIC;
  font-size: 14px;
}

/* table > tbody > tr span {
  margin-right: 10px;
} */

.btn-sm {
  padding: 0.1rem 0.5rem !important;
}

.sMsg {
  margin-left: 10px;
  font-size: 11px;
  color: #249c0e;
}
#vaccination_document {
  color: grey;
  margin-left: 8px;
  cursor: pointer;
}
.dynamicDropdown {
  width: 17%;
  height: 34px;
}
.dynamicDropdown select {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid rgb(206, 205, 205);
}
.dynamicDropdown select:focus {
  outline: none;
}

/****Pod confirmation css- PRIYANSHU****/
.podM {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.podouter1 {
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.podH-1 {
  width: 100%;
  height: 117px;
}
.podr-1 {
  width: 100%;
  height: 33px;
  background-color: #6c757d;
  border-color: #6c757d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.podr-1 span {
  color: #fff;
  font-size: 0.8rem;
  font-weight: 600;
}
.podr-2 {
  width: 100%;
  height: 33px;
  background-color: #fff;
  display: flex;
}
.podr2-c1 {
  width: 35%;
  height: 100%;
  border: 1px solid #736e6e;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.podr2-c2 {
  width: 15%;
  height: 100%;
  border: 1px solid #736e6e;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.podr2-c3 {
  width: 50%;
  height: 100%;
  border: 1px solid #736e6e;
  display: flex;
  justify-content: center;
  align-items: center;
}
.podT-h1 {
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
}
.podr-3 {
  width: 100%;
  height: 51px;
  background-color: white;
  display: flex;
}
.podr3-c1 {
  width: 35%;
  height: 100%;
  border: 1px solid #736e6e;
  border-top: none;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.podr3-c1 span,
.podr3-c2 span,
.podr3-c3 span {
  font-size: 0.9rem;
}
.podr3-c2 {
  width: 15%;
  height: 100%;
  border: 1px solid #736e6e;
  border-top: none;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.podr3-c3 {
  width: 50%;
  height: 100%;
  border: 1px solid #736e6e;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.podr3-mid {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
}
.togglebtnclass {
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
.redbtnclass {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
#fa-math-icon {
  color: #fff;
  position: relative;
  right: 0.7px;
  top: 0.4px;
}
/*  */
.podH-2 {
  width: 100%;
  height: 117px;
  display: flex;
  flex-direction: column;
}
.podH-2-div1 {
  width: 100%;
  height: 33px;
  background-color: #6c757d;
  border-color: #6c757d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.podH-2-div1 span {
  color: #fff;
  font-size: 0.8rem;
  font-weight: 600;
}
.podH-2-div2 {
  width: 100%;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.podH-2-R-1 {
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #736e6e;
  border-right: none;
}
.podH-2-R-1 span {
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
}
.podH-2-R-2 {
  width: 15%;
  height: 100%;
}
.podH-2-R-2 span {
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
}
.podH-2-v1 {
  width: 100%;
  height: 33px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #736e6e;
  border-right: none;
}
.podH-2-v2 {
  width: 100%;
  height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #736e6e;
  border-right: none;
  border-top: none;
}
.podH-2-R-3 {
  width: 50%;
  height: 100%;
}
.podR-3-v1 {
  width: 100%;
  height: 33px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #736e6e;
}
.podR-3-v2 {
  width: 100%;
  height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #736e6e;
  border-top: none;
}
.podH-2-R-3 span {
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
}
/*  */
.podDiv-2 {
  width: 100%;
  height: 112px;
  border: 1px solid #736e6e;
  position: relative;
  top: 25px;
}
.toggle-podDiv-2 {
  width: 100%;
  height: 60px;
  border: 1px solid #736e6e;
  position: relative;
  top: 25px;
}
.podD-2-R-1 {
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  top: 10px;
  justify-content: space-between;
}
.podD-2-I-1 {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.podD-2-I-1 span {
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
}
.podD-2-I-2 {
  width: 32%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
  right: 30px;
}
.choiceyes {
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.choiceyes label {
  color: black;
  font-size: 0.9rem;
  position: relative;
  top: 6px;
}
#yesCheckbox {
  width: 30%;
  height: 50%;
  background-color: #f57421;
}
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  top: 5px;
}

input[type="checkbox"]:after {
  width: 20px;
  height: 20px;
  content: " ";
  display: inline-block;
  visibility: visible;
  background-color: var(--bg--body);
  border: 1px solid var(--clr--accent);
}

input[type="checkbox"]:checked:after {
  content: "\2714";
  /* font-size: 1wpx; */
  padding-left: 3px;
  color: white;
  background-color: var(--clr--accent);
}

.choiceno {
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.choiceno label {
  color: black;
  font-size: 0.9rem;
  position: relative;
  top: 6px;
}

.podD-2-R-2 {
  width: 90%;
  height: 40px;
  position: relative;
  top: 16px;
  left: 16px;
  display: flex;
  justify-content: space-between;
  /* background-color: aquamarine; */
}
.podD-2-R-2-select {
  display: flex;
  justify-content: flex-start;
  position: relative;
  left: 12px;
  width: 35%;
  height: 30px;
}

.other_input {
  width: 30%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  padding-left: 6px;
  position: relative;
  right: 0px;
}
.podD-2-R-2 ::placeholder {
  color: black;
  font-size: 0.7rem;
  padding-left: 6px;
}

.podD-2-R-2 select:focus {
  outline: none !important;
}
/*  */
.podDiv-3 {
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 55px;
  border: 1px solid #736e6e;
}
.name-div {
  width: 93%;
  height: 24px;
  display: flex;
  position: relative;
  top: 15px;
  margin-left: auto;
  margin-right: auto;
}
.name-div1 {
  width: 60%;
  height: 24px;
}
.name-div1 span {
  font-size: 0.8rem;
  font-weight: 700;
  display: flex;
  color: black;
  justify-content: flex-start;
}
.desig-div1 {
  width: 50%;
  height: 24px;
}
.desig-div1 span {
  font-size: 0.8rem;
  font-weight: 700;
  display: flex;
  color: black;
  justify-content: flex-start;
}
.input-div {
  width: 93%;
  height: 33px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  bottom: 12px;
}
.name-input {
  width: 60%;
  height: 100%;
}
.name-input input {
  width: 65%;
  height: 90%;
  display: flex;
  justify-content: flex-start;
  padding-left: 6px;
  border: 1px solid silver;
}
.name-input ::placeholder {
  color: black;
  font-size: 0.7rem;
  padding-left: 6px;
}

.desig-input {
  width: 50%;
  height: 100%;
}

.desig-input input {
  width: 80%;
  height: 92%;
  display: flex;
  justify-content: flex-start;
  padding-left: 6px;
  border: 1px solid silver;
}
.desig-input ::placeholder {
  color: black;
  font-size: 0.7rem;
  padding-left: 6px;
}
/*  */
.podDiv-4outer {
  width: 100%;
  height: 420px;
  position: relative;
  top: 92px;
  border: 1px solid #736e6e;
  margin-bottom: 120px;
}
.podDiv-4 {
  width: 94%;
  height: 40px;
  display: flex;
  position: relative;
  top: 25px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
}
.podsigndiv {
  width: 20%;
  height: 100%;
}
.podsigndiv span {
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
  color: black;
  font-weight: 700;
}
.pod2btns {
  width: 38%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
#selectImage {
  display: none;
}
.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  padding-top: 7.5px;
  width: 47%;
  height: 80%;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  background-color: #ff7200;
}
.selectimg {
  width: 45%;
  height: 80%;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  background-color: #ff7200;
}
.claerbtn {
  width: 45%;
  height: 80%;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  background-color: red;
}
.signaturediv {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 250px;
  position: relative;
  top: 45px;
}
.signcanvas {
  width: 70%;
  height: 210px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border: 2px dashed #888;
  z-index: 2;
}
.signatureimage {
  display: none;
}
.togglesignatureimage {
  width: 100%;
  height: 190px;
  position: relative;
  bottom: 190px;
  z-index: 3;
}
.togglesignatureimage img {
  width: 60%;
  height: 100%;
}
#signcanvasbtn {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: red;
  cursor: pointer;
  border: none;
  position: absolute;
  right: -10px;
  top: -20px;
  z-index: 2;
}
#fa-removeImg {
  position: relative;
  left: -2px;
  color: #fff;
}
#hide-btn {
  display: none;
}
.pod-btn_cont {
  position: absolute;
  bottom: 30px;
  width: 30%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* *****Tagged Image UI CSS***** */
.custom-modal .Ti-modal-body {
  background: #fff;
  width: calc(100% - 10px);
  height: 550px;
  padding: 20px 10px 10px 10px;
  text-align: center;
  overflow-y: scroll !important;
}
.Ti-modal-body .cust-row {
  display: flex;
  width: 100%;
  position: relative;
  /* flex-wrap: wrap; */
}
.TI-outer {
  width: 100%;
  height: 520px;
}
.map-TI-outer {
  width: 100%;
  height: 520px;
}
.TI-inner1 {
  width: 100%;
  height: 40px;
}
.TI-inner1 span {
  color: green;
  font-size: 0.83rem;
  font-weight: 600;
}
.TI-inner1 .fa-check {
  border-radius: 50%;
  position: relative;
  right: 15px;
}
.TI-inner2 {
  width: 100%;
  height: 440px;
  display: flex;
}
.left-arrow-div {
  width: 10%;
  height: 100%;
}
.left-arrow-div #leftArrow {
  width: 35px;
  height: 35px;
  font-size: 30px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid black;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.taggedimage-div {
  width: 80%;
  height: 100%;
}
.taggedimage-div img {
  width: 100%;
  height: 100%;
}
.right-arrow-div {
  width: 10%;
  height: 100%;
}
.right-arrow-div #rightArrow {
  width: 35px;
  height: 35px;
  font-size: 30px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid black;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.TI-inner3 {
  width: 100%;
  height: 20px;
  position: relative;
  top: -15px;
}
.TImap-inner3 {
  width: 100%;
  height: 20px;
  position: relative;
  top: 15px;
}
.bulletDiv {
  width: 15%;
  height: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  /* background-color: yellow; */
  justify-content: space-around;
}
/* .pagination {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2.2px solid #888;
  cursor: pointer;
}
.activepagination {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2.2px solid #888;
  background-color: #888;
  cursor: pointer;
} */
/*  */
.TI-inner4 {
  width: 100%;
  height: 35px;
  position: relative;
  top: -36px;
  opacity: 0.7;
}
.hide {
  display: none;
}

.latLngdiv {
  width: 80%;
  height: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.latLng {
  width: 33%;
  height: 100%;
  background-color: rgb(126, 125, 125);
}
.latLng,
.details span {
  color: white;
}
.map {
  width: 34%;
  height: 100%;
}
.map button {
  width: 100%;
  height: 100%;
  background-color: #ff7200;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 0.8rem;
}
.details {
  width: 33%;
  height: 100%;
  background-color: rgb(126, 125, 125);
}

.mapOuter {
  width: 100%;
  height: 100vh;
}
#mapDimensions {
  width: 100%;
  height: 100%;
}
/* *****view document UI***** */
.ViewDoc-modal-body {
  background: #fff;
  width: calc(100% - 10px);
  height: 260px;
  padding: 20px 10px 10px 10px;
  text-align: center;
  overflow-y: scroll !important;
}
.ToggleViewDoc-modal-body {
  background: #fff;
  width: calc(100% - 10px);
  height: 490px;
  padding: 20px 10px 10px 10px;
  text-align: center;
  overflow-y: scroll !important;
}
.documentOuter {
  width: 100%;
  height: 180px;
  cursor: pointer;
}
.documentheading {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  background-color: rgb(116, 115, 115);
}
.documentheading span {
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  color: #fff !important;
  padding-left: 10px;
}
.documentupload {
  width: 100%;
  height: 150px;
  position: relative;
  top: 20px;
  cursor: pointer;
}
.uploadbtndiv {
  width: 75%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 1.5px dashed black;
  border: 2px dashed #727376;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  transition: 0.3s;
}
.uploadbtndiv button {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: column;
}
.uploadbtndiv button:hover {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 25px rgb(0 0 0 / 15%);
}

#docUploadicon {
  width: 80px;
  position: relative;
  top: 15px;
  margin-left: auto;
  margin-right: auto;
}
.uploadbtndiv span {
  font-size: 1rem;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  color: #727376;
  position: relative;
  top: 18px;
}
.viewdoc-uploadbtn {
  width: 58%;
  height: 94%;
  position: absolute;
  opacity: 0;
}
/*  */
.toggleuploadDiv {
  width: 100%;
  height: auto;
  position: relative;
  top: 50px;
  /* background-color:red; */
}
.uploadedDocDiv {
  width: 100%;
  height: auto;
  position: relative;
  top: 40px;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 20px;
}
.uploadedDocRow {
  width: 90%;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  border: 2px solid #888;
  border-bottom: none;
  position: relative;
}
.uploadedDocRow:nth-last-child(1) {
  border-bottom: 2.2px solid #888;
}

.sno {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid silver;
}
.sno span {
  color: #727376;
  font-size: 0.8rem;
  font-weight: 600;
}
.uploadedDoc-h1 {
  width: 60%;
  height: 100%;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  border: 1px solid #888;
  border-left: none;
  border-top: none;
  border-bottom: none;
}
.uploadedDoc-h1 span {
  font-size: 1rem;
  color: black;
  font-weight: 600;
  position: relative;
}
.uploadedDoc-h2 {
  width: 40%;
  height: 100%;
}
.uploadedDocIcons {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
#viewdoc-view {
  font-size: 18px;
  cursor: pointer;
  color: green;
}
#viewdoc-download {
  font-size: 18px;
  cursor: pointer;
  color: red;
}
.upload-doc-button {
  width: 100%;
  height: 40px;
  margin-top: 70px;
}
.upload-doc-button .btn_cont {
  display: flex;
  justify-content: center;
}
.upload-doc-button .button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  width: 33%;
  background-color: #fff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

/*  */
.docPreview,
.docOverlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block !important;
  z-index: 1000;
}

.docOverlay {
  background-color: rgba(49, 49, 49, 0.8);
}
.doc-close {
  width: 40px;
  height: 40px;
  background-color: #fff;
  position: absolute;
  top: -30px;
  border-radius: 50%;
  right: -20px;
  cursor: pointer;
}

.doc-content {
  width: 44%;
  height: 510px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.1;
  background-color: #ff7200;
  border-radius: 10px;
  text-align: center;
  display: block;
  padding: 16px 10px 10px 10px;
  z-index: 1111;
}
.viewDoc-heading {
  color: #fff;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  position: relative;
  top: -5px;
  font-family: GOTHIC;
}
.doc-inner1 {
  width: 100%;
  height: 95%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 20px 5px 5px 5px;
}
.doc-img-div {
  width: 90%;
  height: 350px;
  margin-right: auto;
  margin-left: auto;
}
.doc-img-div img {
  width: 100%;
  height: 100%;
  border: 0.4px solid silver;
}
.pdfimg {
  transform: rotateX(180deg);
}
.imgview {
  transform: rotateX(0deg);
  background-color: #000;
}
.doc-download-div {
  width: 90%;
  height: 30px;
  position: relative;
  top: 45px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}
.doc-download-div a {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #ff7200;
  border: none;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}
#docView-cross {
  color: #ff7200;
  font-size: 26px;
  position: relative;
  top: 50%;
  font-weight: 300;
  transform: translateY(-50%);
}
/* *****ADD ADDRESS CSS***** */
.addAddressBox {
  background-color: #ff7200;
  padding: 5px;
  width: 97%;
  height: 410px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 42%;
  transform: translateY(-50%);
}
.addaddressinner {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 10px;
  position: relative;
}
.addaddressform {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  justify-content: space-around;
}
.addadresstop {
  width: 100%;
  height: 80%;
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
}
.addaddressleft {
  width: 80%;
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
}
.addaddressd-L0 {
  width: 90%;
  height: 35px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.addaddressd-L0 label {
  width: 30%;
  font-size: 0.9rem;
  color: black;
  padding-top: 10px;
  font-weight: 600;
}
.addaddress-input1 {
  width: 50%;
  height: 100%;
  padding-left: 5px;
  font-size: 0.8rem;
}
.addaddressd-L0 button {
  width: 10%;
  overflow: hidden;
  opacity: 10;
  background-color: transparent;
  border: 1px solid #888;
  border-left: none;
  cursor: pointer;
}
.addaddressd-L0 .addresInput {
  width: 100%;
  height: 90%;
  position: relative;
  bottom: 20px;
  opacity: 0;
  cursor: pointer;
}

#addAddressUpload {
  font-size: 20px;
  cursor: pointer;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.addaddress-input1::placeholder {
  color: #888;
  font-size: 0.8rem;
  padding-left: 10px;
}

.addaddressd-L1 {
  width: 90%;
  height: 35px;
  margin-left: auto;
  margin-right: auto;
}
.addaddressd-L1 label {
  width: 30%;
  font-size: 0.9rem;
  color: black;
  font-weight: 600;
}

.addaddress-input {
  width: 60%;
  height: 100%;
  padding-left: 5px;
}

.addaddress-input::placeholder {
  color: #888;
  font-size: 0.8rem;
  padding-left: 5px;
}

.addaddresscenter {
  width: 0.2%;
  height: 90%;
  background-color: #ff7200;
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translateY(-50%);
}
.addaddressright {
  width: 80%;
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
}
.addaddressd-R1 {
  width: 90%;
  height: 35px;
  margin-left: auto;
  margin-right: auto;
}
.addaddressd-R1 label {
  width: 170px;
  font-size: 0.9rem;
  color: black;
  font-weight: 600;
}
.addaddressbottom {
  width: 90%;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  text-align: center;
  display: flex;
}
.addaddressbottom button {
  width: 20%;
  height: 35px;
  border: none;
  color: #fff;
  font-size: 1rem;
  background-color: #ff7200;
  border-radius: 4px;
  cursor: pointer;
}
.asterisk {
  color: red;
  padding-left: 4px;
}

.pRel {
  position: relative;
}
.pAbs {
  position: absolute;
}
.highcharts-credits{
  display: none;
}
.pflexColumn{
  /* background-color: cyan; */
  
  flex-direction: column;
}
.TimeHeading{
  color: #303030;
  font-weight: 800;
}
.timeValue{
  color: #707070	;
  text-decoration: underline;
  text-underline-offset: 3px;
  font-weight: 800;
}
.pButton{
  width: auto;
  height: 22px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 14px;
  background-color: #ff7200;
  color: #fff;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-size: 11px;
}
.goalMain{
  margin-bottom: 30px;
}
.goalDiv{
  width: 100%;
  height: 590px;
  padding: 10px;
  overflow: auto;
  margin-bottom: 40px;
  box-shadow: 0px 0px 10px #E8E8E8;
}
.goalTopRow{
  width: 100%;
  height: 45px;
  display: flex;
  background-color: #ff7200;
}
.goalRow{
  width: 100%;
  height: 280px;
  display: flex;
  border-bottom: 1px solid silver;
}
.goalSno{
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  border-right: 1px solid #fff;
}
.goalLeft{
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.goalRight{
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}
.goalHeading{
  font-size: 14px;
  color: #000;
  font-weight: 900;
}
.goalVal1{
  font-size: 15px;
  color: #fff;
  font-weight: 900;
  padding-left: 10px;
}
.blacSpan{
  color: #000;
  font-weight: 900;
}
#brandingLogo{
  display: none;
}
.goalBox{
  width: 90%;
  height: auto;
}
.goalBoxRow{
  width: 100%;
  height: auto;
  padding: 6px;
  display: flex;
  align-items: center;
  border: 1px solid #E0E0E0;
  border-bottom: none;
}
.goalBoxRow:last-child{
  border-bottom: 1px solid #E0E0E0;
}
.goalSpan{
  width: 50%;
  font-size: 13px;
  color: #555557;
  font-weight: 800;
  border-right: 1px solid #E0E0E0;
}
.goalVal{
  width: 50%;
  font-size: 13px;
  color: #ff7200;
  font-weight: 800;
  padding-left: 12px;
}